export const themes = {
    default: {
        backgroundColor: 'transparent',
        color: 'var(--color-text-button-default)',
        borderColor: 'var(--color-border-button-default)',
        transitionDuration: 'var(--transition-all-button-default)',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-default-hover)',
            color: 'var(--color-text-button-default-hover)',
            borderColor: 'var(--color-border-button-hover)',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-default-active)',
            color: 'var(--color-text-button-default-active)',
            borderColor: 'var(--color-border-button-default-active)',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-default-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-border-button-disabled)',
        },
    },
    inverted: {
        backgroundColor: 'var(--color-bg-button-inverted)',
        color: 'var(--color-text-button-inverted)',
        borderColor: 'var(--color-border-button-inverted)',
        transitionDuration: 'var(--transition-all-button-default)',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-hover)',
            color: 'var(--color-text-button-inverted-hover)',
            borderColor: 'var(--color-border-button-inverted-hover)',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-active)',
            color: 'var(--color-text-button-inverted-active)',
            borderColor: 'var(--color-border-button-inverted-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-disabled)',
            color: 'var(--color-text-button-inverted-disabled)',
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
    },
    defaultsecondary: {
        backgroundColor: 'var(--color-bg-button-defaultsecondary)',
        color: 'var(--color-text-button-defaultsecondary)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-defaultsecondary)',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-defaultsecondary-hover)',
            color: 'var(--color-text-button-defaultsecondary-hover)',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-defaultsecondary-active)',
            color: 'var(--color-text-button-defaultsecondary-active)',
            borderColor: 'var(--color-border-button-defaultsecondary-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-defaultsecondary-disabled)',
            color: 'var(--color-text-button-defaultsecondary-disabled)',
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
    },
    invertedsecondary: {
        backgroundColor: 'var(--color-bg-button-invertedsecondary)',
        color: 'var(--color-text-button-invertedsecondary)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-invertedsecondary)',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-invertedsecondary-hover)',
            color: 'var(--color-text-button-invertedsecondary-hover)',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-invertedsecondary-active)',
            color: 'var(--color-text-button-invertedsecondary-active)',
            borderColor: 'var(--color-border-button-invertedsecondary-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-invertedsecondary-disabled)',
            color: 'var(--color-text-button-invertedsecondary-disabled)',
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
    },
    link: {
        color: 'var(--color-text-button-link)',
        borderColor: 'transparent',
        hoverStyles: {
            color: 'var(--color-bg-button-link-hover)',
        },
        activeStyles: {
            color: 'var(--color-text-button-link-active)',
            borderColor: 'transparent',
        },
    },
    defaultinverted: {
        backgroundColor: 'var(--color-bg-button-defaultinverted)',
        color: 'var(--color-text-button-defaultinverted)',
        borderColor: 'var(--color-border-button-defaultinverted)',
        transitionDuration: 'var(--transition-all-button-defaultinverted)',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-defaultinverted-hover)',
            color: 'var(--color-text-button-defaultinverted-hover)',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-defaultinverted-active)',
            color: 'var(--color-text-button-defaultinverted-active)',
            borderColor: 'var(--color-border-button-defaultinverted-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-defaultinverted-disabled)',
            color: 'var(--color-text-button-defaultinverted-disabled)',
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
    },
};

// Missing the size? Use $style to add custom styling.
export const sizes = {
    md: {
        padding: '12px',
    },
};

export const themeNames: { [key: string]: string } = {
    // CMS names
    black_outlined: 'default',
    white_outlined: 'inverted',
    black_solid: 'defaultsecondary',
    white_solid: 'invertedsecondary',
};

export const getButtonTheme = (theme = 'default'): string => {
    if (!theme) {
        return 'default';
    }

    return themeNames[theme] || theme;
};
