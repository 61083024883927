const headerThemes = {
    checkout: {
        '--color-bg-header': 'var(--color-base-white)',
        '--color-text-header': 'var(--color-base-black)',
    },
    default: {
        '--color-bg-header': 'var(--color-base-white)',
        '--color-text-header': 'var(--color-base-brand-black)',
        '--color-text-search-header': 'var(--color-text)',
        '--color-top-logo-mobile': 'var(--color-text)',
        '--color-top-logo-site-selector': 'var(--color-base-white)',
    },
};

export default headerThemes;
