import PropTypes from 'prop-types';
import Slider from '@activebrands/core-web/components/Slider';
import media from '@activebrands/core-web/config/media';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { styled } from '@activebrands/core-web/libs/styletron';
import PromotionalHeader from 'components/PromotionalHeader';

const Wrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  display: 'flex',
                  flexDirection: 'column',
              }
            : {},
    [media.min['desktop.sm']]:
        $type === 'article' || $type === 'feature'
            ? {
                  display: 'flex',
                  flexDirection: 'column',
              }
            : {},
}));

const PromotionalHeaderWrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '1 / span 5',
              }
            : {},
    [media.min['desktop.sm']]: {
        gridColumn: '1 / span 3',
    },
}));

const SliderWrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '6 / span 7',
              }
            : {},
    [media.min['desktop.sm']]: {
        gridColumn: '4 / span 9',
    },
}));

const PromotionalSlider = ({
    $paginationStyle = {},
    $style = {},
    button = {},
    heading = '',
    headingFontKeys,
    tagFontKeys = [],
    headingType,
    tag,
    text,
    type,
    ...sliderProps
}) => {
    const slider = createSlider();

    return (
        <Wrapper $style={$style} $type={type}>
            <PromotionalHeaderWrapper $type={type}>
                <PromotionalHeader
                    $paginationStyle={$paginationStyle}
                    $style={{ zIndex: 1 }}
                    button={button}
                    heading={heading}
                    headingFontKeys={headingFontKeys}
                    tagFontKeys={tagFontKeys}
                    headingType={headingType}
                    slider={slider}
                    tag={tag}
                    text={text}
                    type={type}
                />
            </PromotionalHeaderWrapper>
            <SliderWrapper $type={type}>
                <Slider $style={{ padding: '0 var(--margin-content)' }} slider={slider} type={type} {...sliderProps} />
            </SliderWrapper>
        </Wrapper>
    );
};

PromotionalSlider.propTypes = {
    $paginationStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.string,
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    type: PropTypes.string,
};

export default PromotionalSlider;
