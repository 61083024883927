import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const ArrowIcon = ({ color = 'currentColor', size = '16px', $style = {}, viewBox = '0 0 16 17', ...rest }) => {
    const style = { ...$style };

    return (
        <SVG $style={style} width={size} height="auto" fill="none" viewBox={viewBox} {...rest}>
            <path fill={color} d="m16 8.25-8-8v1.5l6 6H.5l-.5.5.5.5H14l-6 6v1.5l8-8Z" />
        </SVG>
    );
};

ArrowIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default ArrowIcon;
