import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import useEvent from '@activebrands/core-web/libs/Events/useEvent';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { useLocation } from '@reach/router';
import LogotypeSymbol from 'components/icons/LogotypeSymbol';
import LogotypeText from 'components/icons/LogotypeText';

const LogotypeLink = styled(Link, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all var(--duration-fast) var(--ease)',
});

const LogoWrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '116px', // symbol logo + gap + text logo
    height: '30px',
});

const Logotype = ({ $style = {} }) => {
    const logoSymbolRef = useRef(null);
    const logoTextRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        logoSymbolRef.current.classList.add('full-logo-symbol');
        logoTextRef.current.classList.add('full-logo-text');
    }, []);

    const setFullLogo = () => {
        logoSymbolRef.current.classList.remove('full-logo-symbol');
        logoSymbolRef.current.classList.add('icon-logo-symbol');
        logoTextRef.current.classList.remove('full-logo-text');
        logoTextRef.current.classList.add('icon-logo-text');
    };

    const setIconLogo = () => {
        logoTextRef.current.classList.remove('icon-logo-text');
        logoTextRef.current.classList.add('full-logo-text');
        logoSymbolRef.current.classList.remove('icon-logo-symbol');
        logoSymbolRef.current.classList.add('full-logo-symbol');
    };

    useDidUpdate(() => {
        setFullLogo();
    }, [location.pathname]);

    useEvent('OVERLAY.STATE_CHANGE', ({ ids }) => {
        const scrollYPosition = document.documentElement.scrollTop;

        if (ids.length === 0) {
            return;
        }

        if (scrollYPosition === 0) {
            setIconLogo();
        }
    });

    useEvent(
        'BODY.SCROLL_DOWN',
        position => {
            const scrollDownThreshold = position.y > 0;
            if (scrollDownThreshold) {
                setFullLogo();
            }
        },
        []
    );

    useEvent(
        'BODY.SCROLL_UP',
        position => {
            const scrollUpThreshold = position.y > 0;
            setIconLogo();
            if (!scrollUpThreshold) {
                setIconLogo();
            }
        },
        []
    );

    const [css] = useStyletron();

    return (
        <LogotypeLink $style={$style} title="Sweet Protection" to="/">
            <LogoWrapper className="logoWrapper">
                <div
                    className={css({
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    })}
                >
                    <div
                        ref={logoSymbolRef}
                        className={css({
                            position: 'absolute',
                            transition: 'all var(--duration-fast) var(--ease)',
                            opacity: 1,
                        })}
                    >
                        <LogotypeSymbol width="27px" />
                    </div>
                </div>
                <div
                    className={css({
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        marginLeft: '-48px',
                    })}
                >
                    <div
                        className={css({
                            transition: 'all var(--duration-fast) var(--ease)',
                            position: 'absolute',
                        })}
                        ref={logoTextRef}
                    >
                        <LogotypeText width="82px" />
                    </div>
                </div>
            </LogoWrapper>
        </LogotypeLink>
    );
};

Logotype.propTypes = {
    $style: PropTypes.object,
};

export default Logotype;
