interface GetHeaderThemeProps {
    heroTextColor: string;
    transparent?: boolean;
}

export const getHeaderTheme = ({ heroTextColor, transparent = false }: GetHeaderThemeProps) => {
    // Change header theme depending on hero content text color
    const transparentWhiteColors = ['white'];
    const transparentBlackColors = ['black'];

    // All page types that have no hero or includes in any transparent types
    let theme = 'default';

    if (transparent && heroTextColor) {
        if (transparentBlackColors.includes(heroTextColor)) {
            theme = 'transparentBlack';
        }
        if (transparentWhiteColors.includes(heroTextColor)) {
            theme = 'transparentWhite';
        }
    }

    return theme;
};
