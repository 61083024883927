import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    height: '100%',
});

const ContentWrapper = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: ['24px', null, null, null, null, '64px'],
    padding: ['24px var(--margin-content)', null, '32px var(--margin-content)', null, null, 'var(--margin-content)'],
});

const Content = styled('div', {
    height: ['auto', null, null, null, 'fill-available'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ['flex-end', null, null, null, 'center'],
    padding: '8px 8px 0 8px',
    width: '100%',
    zIndex: 1,
});

const CategoriesWrapper = styled('div', {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});

const Categories = styled('div', {
    width: '100%',
    height: 'auto',
    zIndex: 1,
});

const StyledTag = styled('tag', {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
    padding: '0 8px',
    ...textStyles['Misc/16_100_-2'],
});

const Discover = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    categoriesTag,
    categoryButtons = [],
    heading,
    headingStyle = 'md',
    headingType,
    id,
    tag,
    text,
    textColor = 'white',
    wrapperLink,
}) => {
    const [css] = useStyletron();

    const tagFontKeys = {
        sm: ['Secondary/16_100_-02', null, null, 'Secondary/20_100_-0375', null, 'Secondary/28_100_-0525'],
        md: ['Secondary/20_100_-0375', null, null, 'Secondary/32_100_-06', null, 'Secondary/40_100_-075'],
    };

    const headingFontKeys = {
        sm: ['Secondary/32_100_-06', null, null, 'Secondary/40_100_-075', null, 'Secondary/56_100_-105'],
        md: ['Primary/40_-05', null, null, 'Primary/64_-12', null, 'Primary/96_-24'],
    };

    const textColorStyle = getTextColorStyles(textColor);

    return (
        <Wrapper id={id}>
            <Link className={css(textColorStyle)} to={wrapperLink}>
                <BackgroundDynamic
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading={backgroundLoading}
                />
                <ContentWrapper $style={textColorStyle}>
                    <Content>
                        <div
                            className={css({
                                position: 'relative',
                                display: 'flex',
                                gridColumn: '4 / span 6',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: '8px',
                            })}
                        >
                            <Paragraph
                                $style={{ display: 'flex', justifyContent: 'center' }}
                                fontKeys={tagFontKeys[headingStyle]}
                            >
                                {tag}
                            </Paragraph>
                        </div>
                        <Heading
                            $style={{ textAlign: 'center', marginBottom: '24px', gridColumn: '2 / span 10' }}
                            as={headingType}
                            fontKeys={headingFontKeys[headingStyle]}
                        >
                            {heading}
                        </Heading>
                        {text && (
                            <div
                                className={css({
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    gridColumn: ['2 / span 10', null, null, '5 / span 4'],
                                    margin: '0 auto',
                                    ...textStyles['Secondary/16_130_-02'],
                                })}
                            >
                                <RichText data={text} />
                            </div>
                        )}
                        <ButtonsList
                            $style={{
                                gridColumn: '4 / span 6',
                                display: 'inline-flex',
                                marginTop: '24px',
                                justifyContent: 'center',
                            }}
                            buttons={buttons}
                        />
                    </Content>
                    <CategoriesWrapper>
                        <Categories>
                            {categoriesTag && <StyledTag>{categoriesTag}</StyledTag>}
                            <ButtonsList
                                $style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    maxWidth: '100%',
                                }}
                                buttons={categoryButtons}
                                fontKeys="Misc/16_100_-02"
                            />
                        </Categories>
                    </CategoriesWrapper>
                </ContentWrapper>
            </Link>
        </Wrapper>
    );
};

Discover.propTypes = {
    background: PropTypes.arrayOf(
        PropTypes.object
        // @todo: This gives "Failed prop type: checker is not a function".
        // PropTypes.oneOfType([contentBackgroundColorProp, contentImageProp, contentVideoProp])
    ),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    categoriesTag: PropTypes.string,
    categoryButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
    wrapperLink: PropTypes.string,
};

export default Discover;
