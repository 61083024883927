import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import InputErrors from 'components/Form/InputErrors';

export const Label = styled('label', ({ $required }) => ({
    color: 'var(--color-text-subtle-input)',
    position: 'absolute',
    top: '-14px',
    ...textStyles['Misc/10_100_-00625'],
    ':after': {
        content: $required ? '"*"' : '""',
    },
}));

const LabelWrapper = styled('div', {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledInput = styled('input', ({ $errors }) => ({
    width: '100%',
    padding: '8px 0px',
    border: 'none',
    borderBottom: $errors ? '1px solid var(--color-text-error)' : '1px solid var(--color-base-brand-grey-neutral)',
    backgroundColor: 'var(--color-bg-text-input)',
    color: 'var(--color-text)',
    ...textStyles['Misc/16_100_-02'],
    '::placeholder': {
        color: 'var(--color-text-subtle-input)',
    },
    ':-webkit-autofill': {
        boxShadow: `0 0 0 30px var(--color-bg) inset !important`,
        ':hover': {
            boxShadow: `0 0 0 30px var(--color-bg) inset !important`,
        },
        ':focus': {
            boxShadow: `0 0 0 30px var(--color-bg) inset !important`,
        },
    },
    ':read-only': {
        color: 'var(--color-text)',
        cursor: `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M14 9v2h-4v-2c0-1.104.897-2 2-2s2 .896 2 2zm10 3c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-8-1h-1v-2c0-1.656-1.343-3-3-3s-3 1.344-3 3v2h-1v6h8v-6z'/%3E%3C/svg%3E"), auto`,
    },
}));

const Description = styled('span', ({ $warning }) => ({
    ...textStyles['Misc/14_110_-0175'],
    display: 'block',
    margin: '4px 0',
    color: $warning ? 'var(--color-text-error)' : 'var(--color-contrast-high)',
    userSelect: 'none',
}));

/**
 * @param {object} [$style] - Optional styling to input.
 * @param {boolean} [required=false] - default value. Sets if input field is required.
 */
const Input = ({
    $style = {},
    ariaLabel = '',
    dataLocale = '',
    defaultValue = '',
    description,
    errorMessageStyle = {},
    errors = [],
    handleChange = () => null,
    inputFontKeys = 'Misc/16_100_-02',
    inputStyle = {},
    label,
    labelStyle,
    inputRef,
    labelWrapperStyle,
    name,
    onBlur = () => null,
    onChange = () => null,
    placeholder,
    required = false,
    type = 'text',
    validate = '',
    value = '',
    ...rest
}) => {
    const [css] = useStyletron();
    const [inputValue, setInputValue] = useState(defaultValue);
    const inputFontStyle = responsiveTextStyles(inputFontKeys);

    const handleKeyDown = e => {
        setInputValue(e.currentTarget.value);
        handleChange(e.currentTarget.value);
        onChange(e);
    };

    const handleBlur = e => {
        setInputValue(e.currentTarget.value.trim());
        onBlur(e);
    };

    useEffect(() => {
        if (value !== '' && inputValue !== value) {
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        if ((defaultValue !== '' && inputValue !== defaultValue) || defaultValue === '') {
            setInputValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div
            className={css({
                position: 'relative',
                marginTop: '12px',
                display: type === 'hidden' ? 'none' : null,
                ...$style,
            })}
        >
            <LabelWrapper $style={labelWrapperStyle}>
                {label && (
                    <Label $required={required} $style={labelStyle} htmlFor={name}>
                        {label}
                    </Label>
                )}
                {errors.length > 0 && (
                    <div
                        className={css({
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        })}
                    >
                        <InputErrors $style={errorMessageStyle} errors={errors} />
                    </div>
                )}
            </LabelWrapper>
            <StyledInput
                $style={{ ...inputFontStyle, ...inputStyle }}
                aria-label={ariaLabel}
                data-locale={dataLocale || null}
                data-validate={validate || null}
                name={name}
                placeholder={placeholder}
                required={required}
                type={type}
                value={inputValue}
                ref={inputRef}
                onBlur={handleBlur}
                onChange={handleKeyDown}
                {...rest}
            />
            {description && <Description>{description}</Description>}
        </div>
    );
};

Input.propTypes = {
    $style: PropTypes.object,
    ariaLabel: PropTypes.string,
    dataLocale: PropTypes.string,
    defaultValue: PropTypes.string,
    description: PropTypes.string,
    errorMessageStyle: PropTypes.object,
    errors: PropTypes.array,
    handleChange: PropTypes.func,
    inputRef: PropTypes.object,
    inputStyle: PropTypes.object,
    inputFontKeys: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    labelWrapperStyle: PropTypes.object,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'tel', 'email', 'number', 'password', 'numeric']),
    validate: PropTypes.string,
    value: PropTypes.string,
};

export default Input;
