import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const List = styled('ul', {
    display: ['none', null, null, null, null, 'flex'],
    flexDirection: 'row',
    marginLeft: 'auto',
});

const SecondaryMenuItem = styled('li', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'var(--height-header)',
});

const SecondaryMenu = ({ items = [], buttonColor, resetNavigation = () => null }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <List>
            {items.map(item => (
                <SecondaryMenuItem key={item.label}>
                    <ThemeButton
                        isVolatile
                        theme="link"
                        $style={{
                            padding: '12px 8px',
                            color: buttonColor,
                            transitionDuration: null,
                            margin: 'unset',
                        }}
                        fontKeys="Secondary/18_120_-2"
                        as={Link}
                        rel={item?.follow === 'no_follow' ? 'nofollow' : undefined}
                        to={item?.link?.url}
                        onClick={() => resetNavigation()}
                    >
                        {item.label}
                    </ThemeButton>
                </SecondaryMenuItem>
            ))}
        </List>
    );
};

SecondaryMenu.propTypes = {
    buttonColor: PropTypes.string,
    items: PropTypes.array.isRequired,
    resetNavigation: PropTypes.func,
};

export default SecondaryMenu;
