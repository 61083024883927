import SVG from '@activebrands/core-web/components/SVG';

interface BasketIconMobileProps {
    $style?: any;
    color?: string;
    fill?: string;
    height?: string | Array<string | null>;
    width?: string | Array<string | null>;
}

const MenuIconMobile = ({
    $style = {},
    color = 'currentColor',
    width = 'auto',
    height = '24px',
    ...rest
}: BasketIconMobileProps) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} fill="none" {...rest} viewBox="0 0 25 24">
            <path stroke={color} d="M.132812 19H24.1328M.132812 12H16.1328M.132812 5H24.1328" />
        </SVG>
    );
};

export default MenuIconMobile;
