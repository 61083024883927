import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const LogotypeIcon = ({
    $style = {},
    color = 'currentColor',
    height = 'auto',
    viewBox = '0 0 27 32',
    width = '27px',
    ...rest
}) => {
    return (
        <SVG {...rest} $style={$style} width={width} version="1.1" viewBox={viewBox}>
            <path
                fill={color}
                d="M13.386 10.1468 8.3133 5.07338 13.386 0l5.0728 5.07338-5.0728 5.07342Zm11.5067 3.4215c-.9561-.9562-2.2091-1.4343-3.4621-1.4343H10.3112c-1.36246 0-2.59624-.5523-3.48959-1.4458-.72248-.72256-1.0015-1.72034-.83832-2.65604l-.31484-.31489-3.78904 3.78953c-.885665.8858-1.434098 2.1101-1.434098 3.4625 0 2.704 2.191768 4.8961 4.895488 4.8961h9.2438l-6.79671 6.5364L13.3854 32l10.8731-10.8745c1.6299-1.6301 2.0651-2.871 2.0651-4.169 0-1.4765-.7155-2.6714-1.4322-3.3882h.0013Z"
            />
        </SVG>
    );
};

LogotypeIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default LogotypeIcon;
