import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const List = styled('ul', {
    display: ['none', null, null, null, null, 'flex'],
    flexDirection: 'row',
    height: '100%',
    margin: '0',
});

const LevelOneItem = styled('li', {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
});

const MenuDot = styled('span', ({ $isActive }) => ({
    display: 'none',

    [media.min['desktop.sm']]: {
        display: 'flex',
        justifyCcontent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--color-icon-navigation-cube)',
        position: 'absolute',
        width: '6px',
        height: '6px',
        left: '50%',
        bottom: $isActive ? '-13px' : '10px',
        zIndex: 'var(--zindex-foreground)',
        opacity: $isActive ? '1' : '0',
        transform: 'rotate(45deg) translate(-50%, -50%) translateX(-3px)',
        transition: 'all var(--duration-fast) var(--ease)',
    },
}));

const PrimaryLevelOneDesktop = ({ items = [], menuValue = '', buttonColor, resetNavigation, setMenuValue }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <List>
            {items.map(levelOneItem => {
                const hasSubmenu = levelOneItem.submenus?.length > 0;
                const isActive = menuValue.current === levelOneItem._uid;

                // Only add isVolatile prop if there is a level one submenu that is a link
                // The reason to do this is because DOM doesn't recognize isVolatile if not a link
                const conditionalProps = {};
                if (!hasSubmenu) {
                    conditionalProps.isVolatile = true;
                }

                return (
                    <LevelOneItem $active={isActive} key={levelOneItem.label}>
                        <ThemeButton
                            theme="link"
                            $style={{
                                color: buttonColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '100%',
                                padding: '12px 8px',
                                margin: 'unset',
                                ':hover > span': {
                                    opacity: 1,
                                    bottom: '-13px',
                                },
                            }}
                            fontKeys="Misc/14_110_-0175"
                            as={hasSubmenu ? null : Link}
                            rel={levelOneItem?.follow === 'no_follow' ? 'nofollow' : undefined}
                            to={levelOneItem?.link?.url}
                            onClick={() => {
                                if (hasSubmenu) {
                                    overlay.closeAll();
                                    overlay.open('navigation');
                                    setMenuValue({
                                        current: hasSubmenu ? levelOneItem._uid : null,
                                        prev: menuValue.current,
                                    });
                                } else {
                                    resetNavigation();
                                }
                            }}
                            {...conditionalProps}
                        >
                            {levelOneItem.label}
                            {hasSubmenu && <MenuDot $isActive={isActive} />}
                        </ThemeButton>
                    </LevelOneItem>
                );
            })}
        </List>
    );
};

PrimaryLevelOneDesktop.propTypes = {
    buttonColor: PropTypes.string,
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func.isRequired,
};

export default PrimaryLevelOneDesktop;
