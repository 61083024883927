import PropTypes from 'prop-types';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import SiteSelectorButton from 'components/Footer/SiteSelectorButton';
import Paragraph from 'components/text/Paragraph';

const SiteSelectorOverlay = loadable(() => import('components/overlays/SiteSelectorOverlay'));

const Wrapper = styled('div', {
    position: 'relative',
    paddingBottom: '116px',
    color: 'var(--color-text-footer-primary)',

    [media.min['tablet.sm']]: {
        borderTop: '1px solid var(--color-border-footer-primary)',
        paddingBottom: 'var(--height-header)',
    },

    [media.min['desktop.sm']]: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '0',
    },
});

const BottomRow = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    padding: '12px',

    [media.min['mobile.lg']]: {
        gap: '16px 0',
        padding: '0 12px',
    },

    [media.min['tablet.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        padding: '0',
        gap: '8px',
    },
});

const PaymentWrapper = styled('div', {
    gridColumn: '1 / span 5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: '16px',
    padding: '12px 0',
    borderTop: '1px solid var(--color-border-footer-primary)',
    borderBottom: '1px solid var(--color-border-footer-primary)',

    [media.min['mobile.lg']]: {
        justifyContent: 'flex-start',
        padding: '0',
        width: '50%',
    },

    [media.min['tablet.sm']]: {
        borderTop: '0',
        borderRight: '1px solid var(--color-border-footer-primary)',
        borderBottom: 'none',
        width: '100%',
        padding: '8px 24px',
        gap: '64px',
    },
});

const FooterBottom = ({ alternateHrefs = [], copywriteText, paymentIcons }) => {
    const [css] = useStyletron();
    const year = new Date().getFullYear();

    return (
        <Wrapper>
            <AnimatedOverlay
                lockScroll
                $style={{
                    height: '100%',
                    width: `calc(var(--width-overlays) - 20px)`,
                    left: 0,
                    right: 0,
                }}
                animation="slideLTR"
                component={SiteSelectorOverlay}
                id="site-selector"
            />
            <BottomRow>
                <div
                    className={css({
                        borderTop: ['1px solid var(--color-border-footer-primary)', null, null, 'none'],
                        display: ['flex', 'null', 'none'],
                        width: '100%',
                        alignItems: 'center',
                    })}
                >
                    <SiteSelectorButton alternateHrefs={alternateHrefs} />
                </div>
                {/* TDOD: replace 'missing_url' with item?.link?.url when urls are available from cms data */}
                <PaymentWrapper>
                    {paymentIcons?.length > 0 &&
                        paymentIcons.map((item, index) =>
                            item.link ? (
                                <Link key={index} rel="noreferrer noopener" target="_blank" to={item.missing_url}>
                                    <div
                                        className={css({
                                            height: '24px',
                                        })}
                                    >
                                        <Image src={item?.icon[0]?.icon[0]?.filename} />
                                    </div>
                                </Link>
                            ) : null
                        )}
                </PaymentWrapper>
                <div
                    className={css({
                        width: ['100%', '50%', null, '100%'],
                        display: ['none', 'flex'],
                        visibility: ['hidden', null, 'visible'],
                        alignItems: 'center',
                        gridColumn: ['6 / span 3', null, null, null, '6 / span 2'],
                    })}
                >
                    <SiteSelectorButton
                        $style={{
                            visibility: ['hidden', null, 'visible'],
                            display: ['none', null, 'block'],
                            width: ['100%', null, null, 'auto'],
                            borderRight: ['none', null, null, null, '1px solid var(--color-border-footer-primary)'],
                            borderTop: ['1px solid var(--color-border-footer-primary)', null, null, 'none'],
                            borderBottom: ['1px solid var(--color-border-footer-primary)', null, null, 'none'],
                        }}
                        alternateHrefs={alternateHrefs}
                    />
                </div>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        gridColumn: ['10', null, null, '9 / span 4'],
                        padding: ['12px 0', null, '0 0 12px 0', '0 24px 0 0'],
                        borderBottom: ['1px solid var(--color-border-footer-primary)', null, null, 'none'],
                    })}
                >
                    <div
                        className={css({
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexShrink: '0',
                        })}
                    >
                        {copywriteText && (
                            <Paragraph
                                $style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                fontKeys="Misc/12_100_-015"
                            >
                                © {year} {copywriteText}
                            </Paragraph>
                        )}
                    </div>
                </div>
            </BottomRow>
        </Wrapper>
    );
};

FooterBottom.propTypes = {
    alternateHrefs: PropTypes.array,
    copywriteText: PropTypes.string,
    paymentIcons: PropTypes.arrayOf(PropTypes.object),
};

export default FooterBottom;
