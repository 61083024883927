import fm from 'format-message';

const errorMessages = {
    badInput: fm('Invalid format'),
    notChecked: fm('Please check the box'),
    passwordMismatch: fm('Password mismatch'),
    patternMismatch: fm('Invalid format'),
    tooShort: fm('Value is too short'),
    typeMismatch: fm('Please enter valid email'),
    valueMissing: fm('Please fill in field'),
    invalidAddress: fm('Please enter a valid address'),
    invalidName: fm('Please enter a valid name'),
    invalidNumber: fm('Please enter a valid mobile number'),
    invalidZip: fm('Please enter a valid zip code'),
    invalidOldPassword: fm('Current password was incorrect'),
};

export default errorMessages;
