import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';
import LogotypeSymbol from 'components/icons/LogotypeSymbol';
import LogotypeText from 'components/icons/LogotypeText';

const LogotypeLink = styled(Link, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all var(--duration-fast) var(--ease)',
    height: '24px',
});

const LogoWrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
});

const LogoTypeMobile = ({ $style = {} }) => (
    <LogotypeLink $style={$style} title="Sweet Protection" to="/">
        <LogoWrapper>
            <LogotypeSymbol $style={{ height: '24px', marginRight: '-6px' }} />
            <LogotypeText $style={{ height: '24px', marginleft: '-6px' }} />
        </LogoWrapper>
    </LogotypeLink>
);

LogoTypeMobile.propTypes = {
    $style: PropTypes.object,
};

export default LogoTypeMobile;
