import PropTypes from 'prop-types';
import { getButtonTheme, sizes, themes } from 'config/branding/component-themes/button-themes';
import { styled } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import Button from 'components/buttons/Button';

export const ButtonsWrapper = styled('div', {
    padding: '0 0 1px 1px',
});

const ThemeButton = ({ $style = {}, size = 'md', theme = 'default', fontKeys = 'Misc/16_100_-02', ...rest }) => {
    const textStyles = responsiveTextStyles(fontKeys);

    return (
        <Button
            $style={{
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'inline-block',
                textAlign: 'center',
                transitionDuration: 'var(--duration-fast)',
                transitionProperty: 'all',
                transitionTimingFunction: 'var(--ease)',
                margin: '0 0 -1px -1px', // margin for compensating for the borders on list items
                ...textStyles,
                ...$style,
            }}
            availableSizes={sizes}
            size={size}
            theme={themes[getButtonTheme(theme)]}
            {...rest}
        />
    );
};

ThemeButton.propTypes = {
    $style: PropTypes.object,
    fontKeys: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ThemeButton;
