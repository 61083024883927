import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    [media.min['desktop.sm']]: {
        flexDirection: 'row',
    },
});

const ContentWrapper = styled('div', {
    marginBottom: ['24px', null, '40px', null, '0'],

    [media.min['desktop.sm']]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '50%',
        order: 2,
    },
});

const TopContent = styled('div', {
    [media.min['mobile.lg']]: {
        width: '83.33%',
    },
});

const BottomContent = styled('div', {
    [media.min['mobile.lg']]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
});

const BackgroundWrapper = styled('div', {
    [media.min['desktop.sm']]: {
        width: '50%',
        order: 1,
        height: '100%',
        margin: '0 8.33%',
    },
});

const BackgroundInnerWrapper = styled('div', {
    position: 'relative',
    aspectRatio: 'var(--ratio-vertical-secondary)',

    [media.min['mobile.lg']]: {
        aspectRatio: 'var(--ratio-square)',
    },

    [media.min['desktop.sm']]: {
        aspectRatio: 'var(--ratio-vertical-primary)',
    },
});

const Category = ({
    background,
    backgroundOverlay,
    button = {},
    buttons = [],
    heading = '',
    headingFontKeys = '',
    headingType = 'h2',
    id = '',
    subHeading = '',
    tag = '',
    text = '',
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    return (
        <Wrapper id={id}>
            <ContentWrapper>
                <TopContent>
                    {tag && <Tag $style={{ marginBottom: ['16px', null, '24px'] }}>{tag}</Tag>}
                    {heading && (
                        <Heading
                            $style={{ marginBottom: ['12px', null, '32px'] }}
                            as={headingType}
                            fontKeys={headingFontKeys}
                        >
                            {heading}
                        </Heading>
                    )}
                    {hasButtons && (
                        <ButtonsList
                            $style={{
                                marginLeft: ['-8px', null, '0'],
                                width: ['calc(100% + 16px)', null, 'auto'],
                                overflowX: ['auto', null, 'hidden'],
                                flexWrap: ['nowrap', null, 'wrap'],
                                '-webkit-overflow-scrolling': 'touch',

                                // Adds the same space as gap in front and after
                                '::before': {
                                    display: ['block', null, 'none'],
                                    content: '""',
                                    width: '0px',
                                },
                                '::after': {
                                    display: ['block', null, 'none'],
                                    content: '""',
                                    width: '0px',
                                },
                            }}
                            buttonStyling={{ padding: ['12px', null, '8px 16px'] }}
                            buttons={buttons}
                            fontKeys="Misc/14_110_-2"
                        />
                    )}
                </TopContent>
                <BottomContent>
                    <div>
                        {subHeading && (
                            <Heading
                                $style={{ marginTop: ['24px', null, null, '40px'] }}
                                as="h3"
                                fontKeys="Misc/14_110_-2"
                            >
                                {subHeading}
                            </Heading>
                        )}
                        {text && (
                            <div className={css({ marginTop: ['4px', null, '8px', null, '0'] })}>
                                <RichText data={text} />
                            </div>
                        )}
                    </div>
                    {button.url && button.label && (
                        <ArrowButton
                            $style={{ width: ['100%', null, 'auto'], marginTop: ['12px', null, '0'] }}
                            as={Link}
                            rel={button.rel}
                            theme={button.theme}
                            to={button.url}
                        >
                            {button.label}
                        </ArrowButton>
                    )}
                </BottomContent>
            </ContentWrapper>
            <BackgroundWrapper>
                <BackgroundInnerWrapper>
                    <BackgroundDynamic background={background} backgroundOverlay={backgroundOverlay} loading="lazy" />
                </BackgroundInnerWrapper>
            </BackgroundWrapper>
        </Wrapper>
    );
};

Category.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    button: PropTypes.shape({
        label: PropTypes.string,
        theme: PropTypes.string,
        url: PropTypes.string,
        rel: PropTypes.string,
    }),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
            rel: PropTypes.string,
        })
    ),
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    subHeading: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.object,
};

export default Category;
