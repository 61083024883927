import PropTypes from 'prop-types';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';

const Button = styled('button', ({ $isOpen }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    cursor: 'pointer',

    ...($isOpen
        ? {
              '::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  bottom: '-1px',
                  width: '24px',
                  height: '1px',
                  transform: 'translateX(-50%)',
              },
          }
        : {}),
}));

const OverlayToggler = ({ children, overlayId = '', overlayProps = {}, ...rest }) => {
    const isOpen = useOverlaySubscriber(overlayId);

    return (
        <Button
            $isOpen={isOpen}
            type="button"
            onClick={() => {
                overlay.closeAll();

                if (!isOpen) {
                    overlay.open(overlayId, overlayProps);
                }
            }}
            {...rest}
        >
            {typeof children === 'function' ? children(isOpen) : children}
        </Button>
    );
};

OverlayToggler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    overlayId: PropTypes.string.isRequired,
    overlayProps: PropTypes.object,
};

export default OverlayToggler;
