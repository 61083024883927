import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import Video from '@activebrands/core-web/components/Video';
import Flexible from 'components/content-components/Flexible';

const VideoPlayer = ({
    background,
    backgroundOverlay,
    heading,
    headingType = 'h2',
    id,
    tag,
    text,
    textColor = 'black',
    videoSettings,
}) => {
    const [showVideo, setShowVideo] = useState(false);

    if (!videoSettings) {
        return null;
    }

    if (showVideo) {
        return <Video $style={{ backgroundColor: 'var(--color-bg)' }} {...videoSettings} />;
    }

    return (
        <Flexible
            background={background}
            backgroundLoading="lazy"
            backgroundOverlay={backgroundOverlay}
            heading={heading}
            headingType={headingType}
            id={id}
            playButtonCallback={() => setShowVideo(true)}
            playButtonLabel={fm('Play')}
            tag={tag}
            text={text}
            textColor={textColor}
        />
    );
};

VideoPlayer.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentHorizontalAlignment: PropTypes.oneOf(['left', 'right', 'center']),
    contentLayout: PropTypes.oneOf(['image-text', 'text-image']).isRequired,
    contentVerticalAlignment: PropTypes.oneOf(['top', 'bottom', 'center']),
    heading: PropTypes.string,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    poster: PropTypes.shape({ url: PropTypes.string, title: PropTypes.string }),
    tag: PropTypes.string,
    text: PropTypes.object,
    textColor: PropTypes.string,
    video: PropTypes.shape({ url: PropTypes.string, title: PropTypes.string }).isRequired,
    videoSettings: PropTypes.shape({
        autoPlay: PropTypes.bool,
        controls: PropTypes.bool,
        loop: PropTypes.bool,
        muted: PropTypes.bool,
        poster: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
};

export default VideoPlayer;
