import { useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSearchQuery from 'queries/useSearchQuery';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import { useWillUnmount } from '@activebrands/core-web/hooks/lifecycle';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { updateQueryParams } from '@activebrands/core-web/utils/query';
import debounce from '@grebban/utils/function/debounce';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { useLocation } from '@reach/router';
import SearchIcon from 'components/icons/SearchIcon';
import Input from 'components/inputs/Input';

const Form = styled('form', {
    width: '180px',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '1px solid var(--color-border-search-header)',
    color: 'inherit',
    cursor: 'text',
    transitionDelay: 'var(--header-transition-delay)',
    transitionTimingFunction: 'var(--ease)',
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'all',
    backgroundColor: 'var(--color-bg-search-header)',
    paddingTop: '8px',
    paddingBottom: '4px',
    margin: '0 16px 4px 8px',
});

const SearchInput = ({ previewDataOverride, ...rest }) => {
    const [state, setState] = useStateContext();
    const location = useLocation();
    const [isSearchOpen] = useOverlaySubscriber(['search']);

    const { headerConfigContent, searchPage } = useSearchQuery();
    const { searchInput } = previewDataOverride || headerConfigContent;

    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = debounce(({ target }) => {
        const query = target.value;

        setState(query);
    }, 500);

    // Navigate to search page and trigger a search there
    const onSubmit = e => {
        e.preventDefault();
        overlay.close('search');
        handleInputChange.cancel();

        let params = {
            q: e.target.elements[0].value,
        };

        // Keep all params except page if the search is performed on the searchpage
        if (location.pathname === searchPage) {
            const queryParams = extractQueryParams(location.search);
            delete queryParams.page;
            params = { ...queryParams, ...params };
        }

        // Update the http-query, this will trigger a search on the search page
        updateQueryParams(params, { basePath: searchPage });
    };

    const setFocus = ({ target }) => {
        let inputEle;

        switch (target.tagName) {
            case 'INPUT':
                inputEle = target;
                overlay.closeAll();
                overlay.open('search', { previewDataOverride });
                break;

            case 'FORM':
                inputEle = target.getElementsByTagName('input')[0];
                break;

            default:
                inputEle = target.closest('form').getElementsByTagName('input')[0];
                break;
        }

        inputEle && inputEle.focus();
    };

    useWillUnmount(() => handleInputChange.cancel());

    return (
        <Form
            {...rest}
            id="search-header"
            $isActive={isSearchOpen}
            $isFocused={isFocused}
            onClick={setFocus}
            onSubmit={onSubmit}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <SearchIcon
                $style={{
                    width: '14px',
                    alignSelf: 'center',
                    marginRight: '8px',
                }}
            />
            <Input
                $style={{ marginTop: '0' }}
                inputStyle={{
                    width: '100%',
                    background: 'none',
                    borderBottom: 'none',
                    border: 'none',
                    padding: '0',
                    color: 'inherit',
                    outline: 'none',
                    lineHeight: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                    '::placeholder': {
                        color: 'var(--color-text-subtle-input)',
                    },
                }}
                inputFontKeys="Misc/14_110_-0175"
                value={state}
                maxLength="100"
                placeholder={searchInput}
                onChange={handleInputChange}
            />
        </Form>
    );
};

SearchInput.propTypes = {
    previewDataOverride: PropTypes.object,
};

export default SearchInput;
