import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import LoadingIcon from 'components/icons/LoadingIcon';
import AddToBasketButton from './AddToBasketButton';
import NotifyMeButton from './NotifyMeButton';
import SuccessMessage from './SuccessMessage';

const QuickshopOverlay = loadable(() => import('components/product/Quickshop/QuickshopOverlay'));

const stateButtons = {
    default: AddToBasketButton,
    notInStock: NotifyMeButton,
    success: SuccessMessage,
    loading: LoadingIcon,
};

const Quickshop = ({
    activePrice,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    id,
    name,
    originalPrice,
    uri,
    variations = [],
}) => {
    const [isOpen, setIsOpen] = useState();
    const [buttonState, setButtonState] = useState('default');
    const prevButtonRef = useRef();

    //TODO: Add when Notify Me is done
    // const inStock = variations.some(v => v.inStock);
    const isOnlyOneVariant = variations.length === 1;

    let StateButton = stateButtons[buttonState];

    //TODO: Add when Notify Me is done
    // if (!inStock) {
    //     StateButton = stateButtons.notInStock;
    // }

    useEffect(() => {
        prevButtonRef.current = buttonState;
        const prevButtonState = prevButtonRef.current;

        // Only update if the state has changed
        if (buttonState !== prevButtonState) {
            StateButton = stateButtons[buttonState];
        }
    }, [buttonState]);

    const buttonStyles = {
        position: 'absolute',
        right: '0',
        bottom: '0',
        zIndex: 2,
        padding: '12px',
        height: 'auto',
        color: 'var(--color-text-product-card)',
    };

    return (
        <>
            <StateButton
                $style={buttonStyles}
                activePrice={activePrice}
                addedToBasketAlgoliaData={addedToBasketAlgoliaData}
                addedToBasketData={addedToBasketData}
                color={color}
                fontKeys="Misc/16_100_-2"
                id={id}
                instantBuy={isOnlyOneVariant}
                name={name}
                originalPrice={originalPrice}
                setButtonState={setButtonState}
                setIsOpen={setIsOpen}
                uri={uri}
                variations={variations}
            />
            {isOpen && (
                <QuickshopOverlay
                    $style={{
                        display: ['none', null, null, 'initial'],
                        position: 'absolute',
                        transform: isOpen ? 'translateY(0)' : 'translateY(110%)',
                        transition: `transform var(--transition-fast)`,
                    }}
                    addedToBasketAlgoliaData={addedToBasketAlgoliaData}
                    addedToBasketData={addedToBasketData}
                    isOpen={isOpen}
                    color={color}
                    setButtonState={setButtonState}
                    setIsOpen={setIsOpen}
                    uri={uri}
                    variations={variations}
                />
            )}
        </>
    );
};

Quickshop.propTypes = {
    activePrice: PropTypes.string,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.shape({
        imageSrc: PropTypes.string,
        link: PropTypes.string,
        name: PropTypes.string,
        priceWithCurrency: PropTypes.string,
        salePriceWithCurrency: PropTypes.string,
    }),
    color: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    originalPrice: PropTypes.string,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default Quickshop;
