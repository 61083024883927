import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AccountIcon = ({
    $style = {},
    color = 'currentColor',
    fill = 'none',
    height = 'auto',
    width = '16px',
    ...rest
}) => {
    return (
        <SVG $style={$style} width={width} height={height} viewBox="0 0 16 16" fill={fill} {...rest}>
            <path d="M15.5 11.2071V15.5H0.5V11.2071L2.20711 9.5H13.7929L15.5 11.2071Z" stroke={color} />
            <rect x="11" y="0.5" width="6" height="6" rx="3" transform="rotate(90 11 0.5)" stroke={color} />
        </SVG>
    );
};

AccountIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AccountIcon;
