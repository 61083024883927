import PropTypes from 'prop-types';
import { sizes } from 'config/branding/component-themes/button-themes';
import textStyles from 'config/branding/textStyles';
import { getBreakpoint } from '@activebrands/core-web/config/breakpoints';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import ThemeButton from 'components/buttons/ThemeButton';
import AddToBasketIcon from 'components/icons/AddToBasketIcon';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
});

// @todo: add onclick for adding to wishlist
const AddToBasketCardEnd = ({
    $style,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    isLoading = false,
    isQuickshopOpen,
    name,
    priceWithCurrency,
    salePriceWithCurrency,
    setIsLoading = () => null,
    setIsQuickshopOpen = () => null,
    uri = '',
    variations = [],
}) => {
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();
    const isOnlyOneVariant = variations.length === 1;
    let Icon;

    if (isQuickshopOpen) {
        Icon = StateIcon;
    } else {
        Icon = AddToBasketIcon;
    }

    if (isLoading) {
        Icon = LoadingIcon;
    }

    const handleAddToBasket = async variation => {
        setIsLoading(true);
        try {
            const response = await dispatch(addBasketItem(variation.id, variation.trackingMeta));
            if (response.status === 200 || response.status === 201) {
                notifications.push(AddedToBasket, addedToBasketData, 4500);
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleButtonClick = variation => {
        setIsQuickshopOpen(!isQuickshopOpen);

        const breakpoint = getBreakpoint('mobile.lg');

        if (isOnlyOneVariant) {
            handleAddToBasket(variation);
        } else if (breakpoint.to > window?.innerWidth) {
            overlay.open('quickshop', {
                activePrice: salePriceWithCurrency,
                addedToBasketAlgoliaData,
                addedToBasketData,
                color,
                name,
                originalPrice: priceWithCurrency,
                uri,
                variations,
            });
        }
    };

    return (
        <Wrapper $style={$style}>
            <ThemeButton
                $style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ['center', null, null, 'initial'],
                    gap: '8px',
                    padding: [0, null, null, sizes.md.padding],
                    height: ['40px', null, null, 'auto'],
                    width: ['40px', null, null, 'auto'],
                    ':hover:not(:disabled):not(.disabled)': {
                        color: 'var(--color-text-button-inverted)',
                        background: 'var(--color-bg-quickshop-hover)',

                        '::before': {
                            position: 'absolute',
                            right: '36px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            content: '""',
                        },
                    },
                    ':disabled': {
                        ...textStyles['Misc/14_100_-0175_LT'],
                    },
                }}
                theme="defaultsecondary"
                type="button"
                onClick={() => handleButtonClick(variations[0])}
            >
                <Icon size="16px" state="close" />
            </ThemeButton>
        </Wrapper>
    );
};

AddToBasketCardEnd.propTypes = {
    $style: PropTypes.object,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    isQuickshopOpen: PropTypes.bool,
    name: PropTypes.string,
    priceWithCurrency: PropTypes.string,
    salePriceWithCurrency: PropTypes.string,
    setIsLoading: PropTypes.func,
    setIsQuickshopOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default AddToBasketCardEnd;
