import PropTypes from 'prop-types';
import Slider from '@activebrands/core-web/components/Slider';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import ArrowIcon from 'components/icons/ArrowIcon';
import ArrowPagination from 'components/slider/ArrowPagination';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const TopPaginationSlider = ({
    $paginationStyle = {},
    $paginationWrapperStyle = {},
    $style = {},
    button = {},
    buttonIconStyling = {},
    heading = '',
    headingFontKeys,
    headingType = '',
    headingStyle = {},
    ...sliderProps
}) => {
    const slider = createSlider();
    const [css] = useStyletron();

    return (
        <div className={css($style)}>
            <div
                className={css({
                    alignItems: ['flex-start', null, 'center'],
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'space-between',
                    marginBottom: '24px',
                    flexDirection: ['column', null, 'row'],
                    ...headingStyle,
                })}
            >
                {heading && (
                    <Heading $style={{ marginRight: 'auto' }} as={headingType} fontKeys={headingFontKeys}>
                        {heading}
                    </Heading>
                )}
                {button.label && button.url && (
                    <ThemeButton
                        $style={{ display: ['none', null, null, 'block'] }}
                        as={Link}
                        rel={button.rel}
                        theme={button.theme}
                        to={button.url}
                    >
                        {button.label}
                        <ArrowIcon $style={{ transform: 'rotate(180deg)', ...buttonIconStyling }} />
                    </ThemeButton>
                )}
                <div className={css({ display: 'flex', ...$paginationWrapperStyle })}>
                    <ArrowPagination $style={{ marginLeft: '32px', ...$paginationStyle }} slider={slider} />
                </div>
            </div>
            <Slider
                $style={{ padding: '0 var(--margin-content)' }}
                slider={slider}
                allSlideItemsStyle={{ scrollSnapAlign: 'unset' }}
                {...sliderProps}
            />
        </div>
    );
};

TopPaginationSlider.propTypes = {
    $paginationStyle: PropTypes.object,
    $paginationWrapperStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    buttonIconStyling: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingStyle: PropTypes.object,
    headingType: PropTypes.string,
};

export default TopPaginationSlider;
