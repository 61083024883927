import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import debounce from '@grebban/utils/function/debounce';
import LoadingIcon from 'components/icons/LoadingIcon';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';

const Text = styled('span', {
    color: 'var(--color-text-product-card-mini)',
    display: 'flex',
    lineHeight: '1',
    ...textStyles['Misc/12_100_-2'],
});

const Button = styled('button', {
    display: 'flex',
    color: 'var(--color-text-product-card-mini)',
    alignItems: 'flex-end',
});

const AmountIncrementer = ({ count, step = 1, loading = false, onChange, $style }) => {
    const [current, setCurrent] = useState(count);
    const [css] = useStyletron();

    useEffect(() => {
        if (current !== count) {
            setCurrent(count);
        }
    }, [count]);

    const debouncedFunc = useMemo(() => debounce(onChange), [onChange]);

    const disableStyle = {
        opacity: `${loading ? '0.6' : '1'}`,
        cursor: `${loading ? 'not-allowed' : 'pointer'}`,
    };

    const decrementAmount = () => {
        debouncedFunc(-step);
        setCurrent(current - step);
    };

    const incrementAmount = () => {
        debouncedFunc(step);
        setCurrent(current + step);
    };

    return (
        <div
            className={css({
                gap: '12px',
                display: 'flex',
                alignItems: 'flex-end',
                ...$style,
            })}
        >
            <Button $style={disableStyle} type="button" onClick={loading ? () => {} : decrementAmount}>
                <MinusIcon size="12px" />
            </Button>

            {loading ? <LoadingIcon /> : <Text>{count}</Text>}

            <Button $style={disableStyle} type="button" onClick={loading ? () => {} : incrementAmount}>
                <PlusIcon size="12px" />
            </Button>
        </div>
    );
};

AmountIncrementer.propTypes = {
    $style: PropTypes.object,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    step: PropTypes.number,
};

export default AmountIncrementer;
