import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SquareImage from 'components/SquareImage';

const Wrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
});

const ColorSwatches = ({ $style = {}, currentVariationId = '', relatedVariations = [], maxColors = 4 }) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            {relatedVariations.slice(0, maxColors).map(({ colorHex, colorImageUrl, colorName, id, name, uri }) => (
                <Link key={id} title={colorName} to={uri}>
                    <SquareImage
                        $style={{
                            height: '12px',
                            width: '12px',
                            transform: 'rotate(45deg)',
                            backgroundColor: `#${colorHex}`,
                        }}
                        isSelected={id === currentVariationId}
                        name={name}
                        src={colorImageUrl ? { width: 28, url: colorImageUrl, format: 'jpg' } : undefined}
                    />
                </Link>
            ))}
            {relatedVariations.length > maxColors && (
                <span className={css({ marginBottom: '1px', display: 'block', ...textStyles['Misc/10_100_-00625'] })}>
                    +{relatedVariations.length - maxColors}
                </span>
            )}
        </Wrapper>
    );
};

ColorSwatches.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    maxColors: PropTypes.number,
    relatedVariations: PropTypes.array,
};

export default ColorSwatches;
