import PropTypes from 'prop-types';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowIcon from 'components/icons/ArrowIcon';
import CloseIcon from 'components/icons/CloseIcon';
import Paragraph from 'components/text/Paragraph';
import TopLogotypeMobile from './LogoTypeMobile';

const Wrapper = styled('div', {
    position: 'sticky',
    top: 0,
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
    backgroundColor: 'var(--color-bg-header)',
});

const PrimaryMenuHeading = ({
    $style,
    text = '',
    resetNavigation = () => null,
    toggleSiteSelector = () => null,
    isSiteSelectorOpen,
}) => {
    const [css] = useStyletron();
    const openPrimaryMenu = () => {
        overlay.open('navigation'), overlay.close('site-selector');
    };

    return (
        <Wrapper $style={$style}>
            {!!isSiteSelectorOpen ? (
                <ArrowIcon
                    $style={{
                        transform: 'rotate(-180deg)',
                        display: ['block', null, null, null, null, 'none'],
                        margin: '12px',
                    }}
                    height="16px"
                    onClick={() => toggleSiteSelector() || openPrimaryMenu()}
                />
            ) : (
                <Paragraph
                    $style={{
                        color: 'var(--color-text)',
                        display: ['block', null, null, null, null, 'none'],
                        margin: '12px',
                    }}
                    fontKeys="Misc/14_110_-0175"
                >
                    {text}
                </Paragraph>
            )}

            <div
                className={css({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >
                <TopLogotypeMobile $style={{ display: ['block', null, null, null, null, 'none'], height: '24px' }} />
            </div>

            <CloseIcon
                $style={{
                    display: ['block', null, null, null, null, 'none'],
                    height: '40px',
                    width: '40px',
                    cursor: 'pointer',
                    padding: '12px',
                }}
                size="16px"
                onClick={() => resetNavigation()}
            />
        </Wrapper>
    );
};

PrimaryMenuHeading.propTypes = {
    $style: PropTypes.object,
    isSiteSelectorOpen: PropTypes.bool,
    resetNavigation: PropTypes.func,
    text: PropTypes.string,
    toggleSiteSelector: PropTypes.func,
};

export default PrimaryMenuHeading;
