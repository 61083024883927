import SVG from '@activebrands/core-web/components/SVG';

interface BasketIconMobileProps {
    $style?: any;
    color?: string;
    fill?: string;
    height?: string | Array<string | null>;
    width?: string | Array<string | null>;
}

const BasketIconMobile = ({
    $style,
    color = 'currentColor',
    width = 'auto',
    height = '24px',
    ...rest
}: BasketIconMobileProps) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} viewBox="0 0 25 24" fill="none">
            <path d="M2.59961 23.5V3.5H22.5996V23.5H2.59961Z" stroke={color} />
            <path d="M8.59961 4V0.5H16.5996V4" stroke={color} />
        </SVG>
    );
};

export default BasketIconMobile;
