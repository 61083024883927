import fm from 'format-message';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import FeaturedItems from 'components/Header/Navigation/PrimaryMenu/FeaturedItems';
import OverlayHeading from 'components/Header/OverlayHeading';
import ThemeButton from 'components/buttons/ThemeButton';
import PrimaryMenuBackdrop from './PrimaryMenuBackdrop';

const Wrapper = styled('div', ({ $isActive, $level }) => ({
    position: 'fixed',
    height: '100%',
    left: 0,
    top: 0,
    visibility: $isActive ? 'visible' : 'hidden',
    width: '100vw',
    transform: 'none',
    zIndex: `calc(var(--zindex-header) + 5 + ${$level})`, // All menus are located on top each other rendered in the dom. This makes the levels stack in correct order in mobile.

    [media.min['desktop.sm']]: {
        transform: 'translate3d(0, 0, 0)',
        left: `calc(var(--width-overlays) - 19px)`, // - 19px because of the menu dots that has to go to the end of the overlay width and overlap the submenulevel a bit. Due to zindex and the way this navigation is built, we need to do this.
    },
}));

const InnerWrapper = styled('div', ({ $level }) => ({
    height: '100%',
    position: 'relative',
    width: 'var(--width-overlays)',
    zIndex: `calc(var(--zindex-header) + 7 + ${$level})`,
    backgroundColor: 'var(--color-bg-navigation)',

    [media.min['desktop.sm']]: {
        backgroundColor: 'transparent',
    },
}));

const Section = styled('div', ({ $level }) => ({
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: `calc(var(--zindex-header) + 8 + ${$level})`,
    backgroundColor: 'transparent',

    '::after': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'block',
        content: '',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    },

    [media.min['desktop.sm']]: {
        paddingBottom: 0,
        left: 0,
        width: '100%',
    },
}));

const Nav = styled('section', ({ $level }) => ({
    position: 'relative',
    color: 'var(--color-text-navigation)',
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    zIndex: `calc(var(--zindex-header) + 9 + ${$level})`,

    [media.min['desktop.sm']]: {
        paddingTop: '24px',
        backgroundColor: 'transparent',
    },
}));

const MenuDot = styled('div', ({ $isActive }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--color-icon-navigation-cube)',
    width: '6px',
    height: '6px',
    bottom: '15px',
    right: '12px',
    transform: 'rotate(45deg)',
    transition: 'right var(--duration-fast) var(--ease)',

    [media.min['desktop.sm']]: {
        right: $isActive ? '3.5px' : '30px',
    },
}));

const SubMenuLevel = ({
    featuredItems = [],
    featuredLabel = '',
    isActive = false,
    level,
    menuValue = {},
    parentLink = {},
    resetNavigation,
    setMenuValue,
    submenus = [],
    parentLabel = '',
}) => {
    const [css] = useStyletron();
    const hasFeaturedItems = featuredItems.length > 0;

    const firstLevel = level === 1;

    return (
        <Wrapper $isActive={isActive} $level={level}>
            <PrimaryMenuBackdrop
                $isOpen={isActive}
                $zIndex={`calc(var(--zindex-header) + (6 + ${level}))`}
                onClick={() => resetNavigation()}
            />
            <InnerWrapper $level={level}>
                <Section $isActive={isActive} $level={level}>
                    <Nav $level={level}>
                        <div
                            className={css({
                                display: ['none', null, null, null, null, 'block'],
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                bottom: '0',
                                left: 'calc(var(--width-overlays) - 20px)',
                                backgroundColor: 'transparent',
                                borderLeft: '1px solid var(--color-border-navigation)',
                            })}
                        />
                        <div
                            className={css({
                                display: ['none', null, null, 'block'],
                                position: 'absolute',
                                top: '0',
                                right: '20px',
                                bottom: '0',
                                left: '0',
                                backgroundColor: 'var(--color-bg-navigation)',
                                zIndex: '-1',
                            })}
                        />
                        <OverlayHeading
                            firstLevel={firstLevel}
                            menuValue={menuValue}
                            resetNavigation={resetNavigation}
                            setMenuValue={setMenuValue}
                            parentLabel={parentLabel}
                        />
                        {hasFeaturedItems && (
                            <FeaturedItems
                                $style={{ padding: '0 8px 32px 8px' }}
                                items={featuredItems}
                                label={featuredLabel}
                            />
                        )}

                        <ul
                            className={css({
                                display: 'flex',
                                flexFlow: 'column',
                                gap: '12px 0',
                                padding: [null, null, null, null, null, '0 12px'],
                                marginBottom: '24px',
                            })}
                        >
                            {submenus?.map((levelTwoItem, index) => {
                                const subMenuIsActive = menuValue.current === levelTwoItem._uid;
                                const hasSubmenu = levelTwoItem.submenus?.length > 0;

                                return (
                                    <li key={`${index}_${levelTwoItem._uid}`}>
                                        <ThemeButton
                                            isVolatile
                                            theme="link"
                                            $style={{
                                                position: 'relative',
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '6px 12px',
                                                transitionDuration: 'var(--duration-fast)',
                                                transitionProperty: 'color',
                                                transitionTimingFunction: 'var(--ease)',
                                                whiteSpace: 'unset',
                                                textAlign: 'left',
                                                margin: 'unset',

                                                [media.min['desktop.sm']]: {
                                                    zIndex: 'calc(var(--zindex-header) + 10)',
                                                    ':hover > div': {
                                                        right: '3.5px',
                                                    },
                                                },
                                            }}
                                            fontKeys="Secondary/24_100_-03"
                                            as={Link}
                                            to={levelTwoItem?.link?.url}
                                            onClick={e => {
                                                if (hasSubmenu) {
                                                    e.preventDefault();
                                                    setMenuValue({
                                                        current: hasSubmenu ? levelTwoItem._uid : null,
                                                        prev: menuValue.current,
                                                    });
                                                } else {
                                                    resetNavigation();
                                                }
                                            }}
                                        >
                                            <span className={css({ width: '80%' })}>{levelTwoItem.label}</span>
                                            {hasSubmenu && <MenuDot $isActive={subMenuIsActive} />}
                                        </ThemeButton>

                                        <SubMenuLevel
                                            featuredItems={levelTwoItem.featuredItems}
                                            featuredLabel={levelTwoItem.featuredLabel}
                                            id={levelTwoItem._uid}
                                            isActive={subMenuIsActive}
                                            level={level + 1}
                                            menuValue={menuValue}
                                            parentLabel={levelTwoItem.label}
                                            parentLink={levelTwoItem.link}
                                            resetNavigation={resetNavigation}
                                            setMenuValue={setMenuValue}
                                            subMenuIsActive={subMenuIsActive}
                                            submenus={levelTwoItem.submenus}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className={css({
                                display: 'flex',
                                marginTop: '8px',
                                marginBottom: '24px',
                                width: ['100%', null, null, null, null, 'calc(100% - 20px)'],
                            })}
                        >
                            <ThemeButton
                                isVolatile
                                theme="defaultsecondary"
                                $style={{
                                    width: '100%',
                                    display: 'flex',
                                    padding: '12px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: ['0 12px', null, null, null, null, '0 24px'],
                                    transitionDuration: 'unset',
                                    transitionProperty: 'background-color',
                                    transitionTimingFunction: 'unset',
                                }}
                                fontKeys="Misc/16_100_-02"
                                as={Link}
                                to={parentLink?.url}
                            >
                                {fm('See all')}
                            </ThemeButton>
                        </div>
                    </Nav>
                </Section>
            </InnerWrapper>
        </Wrapper>
    );
};

SubMenuLevel.propTypes = {
    featuredItems: PropTypes.array,
    featuredLabel: PropTypes.string,
    isActive: PropTypes.bool,
    level: PropTypes.number,
    menuValue: PropTypes.object,
    parentLabel: PropTypes.string,
    parentLink: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
    submenus: PropTypes.array,
};

export default SubMenuLevel;
