import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import Price from '@activebrands/core-web/components/Price';
import SpinnerIcon from '@activebrands/core-web/components/icons/SpinnerIcon';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import getCampaignPrice from '@activebrands/core-web/utils/product/get-campaign-price';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import Badges from 'components/Badges';
import ColorSwatches from 'components/product/ColorSwatches';
import AddToBasketCardEnd from './AddToBasketCardEnd';
import BasketCardEnd from './BasketCardEnd';
import ColorSwatchCardEnd from './ColorSwatchCardEnd';
import OrderCardEnd from './OrderCardEnd';
import QuickshopMiniDesktop from './QuickshopMiniDesktop';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});

const InnerWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: 'var(--color-bg-product-card-mini)',
    transition: 'opacity var(--transition-slow)',
});

export const ImageAndInfoWrapper = styled(Link, {
    display: 'flex',
    flex: 1,
});

export const ImageWrapper = styled('div', {
    display: 'flex',
    position: 'relative',
    flexShrink: '0',
});

export const ProductInfo = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px',
});

export const Name = styled('h3', {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginBottom: '16px',
    ...textStyles['Misc/14_110_500_-0175'],
    color: 'var(--color-text-product-card-mini)',
    lineHeight: 'unset',

    [media.min['desktop.sm']]: {
        ...textStyles['Misc/16_120_500_-02'],
    },
});

export const Text = styled('p', {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'var(--color-text-subtle-product-card-mini)',
    ...textStyles['Misc/12_100_-015'],
    lineHeight: 'unset',

    [media.min['desktop.sm']]: {
        ...textStyles['Misc/14_110_-0175'],
    },
});

export const Remove = styled('button', {
    textAlign: 'right',
    paddingBottom: '4px',
});

export const Loading = styled(SpinnerIcon, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const cardEnds = {
    addToBasket: AddToBasketCardEnd,
    basket: BasketCardEnd,
    colorSwatch: ColorSwatchCardEnd,
    wishlist: AddToBasketCardEnd,
    order: OrderCardEnd,
};

const ProductCardMini = ({
    $style = {},
    badges = [],
    color,
    id,
    imageLoading = 'lazy',
    innerWrapperStyle = {},
    line,
    media = {},
    name,
    onClick = () => null,
    price: defaultPrice,
    quantity,
    relatedVariations = [],
    showFavorite = true,
    showPrice = true,
    size,
    to,
    type = 'addToBasket',
    uri,
    variations = [],
}) => {
    const [isQuickshopOpen, setIsQuickshopOpen] = useState(false);
    const [buttonState, setButtonState] = useState('default');
    const [isLoading, setIsLoading] = useState(false);

    // If there is a active campaign, use that price instead
    const campaignPricelist = useSelector(state => state.campaignSite.campaignPricelist);
    const campaignPrice = getCampaignPrice(id, campaignPricelist);
    const price = campaignPrice || defaultPrice;

    const CardEnd = cardEnds[type];
    const hasRelatedVariations = relatedVariations.length > 0;
    const addedToBasketData = {
        color,
        media,
        name,
        price,
        uri,
    };

    const isTypeColorSwatch = type === 'colorSwatch';
    const isTypeValueProduct = media.typeValue === 'Product';

    return (
        <Wrapper $style={$style}>
            {isLoading && <Loading />}
            <InnerWrapper $style={{ flexDirection: 'row', opacity: isLoading ? 0.5 : 1, ...innerWrapperStyle }}>
                <ImageAndInfoWrapper to={uri || to} onClick={onClick}>
                    <ImageWrapper>
                        {media && (
                            <Image
                                $style={{
                                    height: '110px',
                                    width: '88px',
                                    objectFit: isTypeValueProduct ? 'contain' : 'cover',
                                    padding: isTypeValueProduct ? 'var(--padding-image-productCard)' : '0px',
                                    backgroundColor: 'var(--color-bg-image)',
                                }}
                                alt={name}
                                draggable={false}
                                loading={imageLoading}
                                src={{ url: media.url, width: [200] }}
                            />
                        )}
                    </ImageWrapper>
                    <ProductInfo
                        $style={{
                            flexShrink: 1,
                            minWidth: '0',
                            display: 'flex',
                            padding: isTypeColorSwatch ? '4px 8px' : '10px',
                        }}
                    >
                        <div>
                            {!!badges.length && <Badges list={badges} />}
                            {name && <Name>{name}</Name>}
                            {color && !isTypeColorSwatch && (
                                <Text title={color}>
                                    {size ? `${fm(color)}, ${fm(size).toUpperCase()}` : fm(color)}
                                </Text>
                            )}
                            {hasRelatedVariations && isTypeColorSwatch && (
                                <ColorSwatches
                                    currentVariationId={id}
                                    relatedVariations={relatedVariations}
                                    textStyles="Misc/10_100_-00625"
                                />
                            )}
                        </div>
                        {showPrice && price?.salePriceWithCurrency && (
                            <Price
                                activePrice={price.salePriceWithCurrency}
                                fontStyling={responsiveTextStyles([
                                    'Misc/12_100_-015',
                                    null,
                                    null,
                                    null,
                                    null,
                                    'Misc/14_100_-2',
                                ])}
                                originalPrice={price.priceWithCurrency}
                            />
                        )}
                    </ProductInfo>
                </ImageAndInfoWrapper>
                <CardEnd
                    addedToBasketData={addedToBasketData}
                    color={color}
                    id={id}
                    image={media.url}
                    isQuickshopOpen={isQuickshopOpen}
                    line={line}
                    name={name}
                    priceWithCurrency={price?.priceWithCurrency}
                    quantity={quantity}
                    relatedVariations={relatedVariations}
                    salePriceWithCurrency={price?.salePriceWithCurrency}
                    setIsLoading={setIsLoading}
                    setIsQuickshopOpen={setIsQuickshopOpen}
                    showFavorite={showFavorite}
                    size={size}
                    uri={uri}
                    variations={variations}
                />
            </InnerWrapper>
            {isQuickshopOpen && (
                <QuickshopMiniDesktop
                    $style={{ display: ['none', null, null, 'flex'] }}
                    addedToBasketData={addedToBasketData}
                    buttonState={buttonState}
                    color={color}
                    name={name}
                    setButtonState={setButtonState}
                    setIsLoading={setIsLoading}
                    setIsQuickshopOpen={setIsQuickshopOpen}
                    uri={uri}
                    variations={variations}
                />
            )}
        </Wrapper>
    );
};

ProductCardMini.propTypes = {
    $style: PropTypes.object,
    badges: PropTypes.array,
    color: PropTypes.string,
    id: PropTypes.string,
    imageLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    innerWrapperStyle: PropTypes.object,
    line: PropTypes.string,
    media: PropTypes.object,
    name: PropTypes.string,
    onClick: PropTypes.func,
    price: PropTypes.object,
    quantity: PropTypes.number,
    relatedVariations: PropTypes.array,
    showFavorite: PropTypes.bool,
    showPrice: PropTypes.bool,
    size: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(cardEnds)).isRequired,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default ProductCardMini;
