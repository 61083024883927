import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    color: 'var(--color-text-badge)',
    backgroundColor: 'var(--color-bg-badge-brand-primary)',
    minWidth: 'fit-content',
    ...textStyles['Misc/14_110_-0175'],
});

const ArticleTag = ({ $style = {}, children }) => <Wrapper $style={$style}>{children}</Wrapper>;

ArticleTag.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node,
};

export default ArticleTag;
