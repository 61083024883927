import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import generateRandomString from '@grebban/utils/string/generateRandomString';

const Input = styled('input', {
    display: 'none',

    ':checked + label::before': {
        padding: '2px',
    },

    ':focus + label:before': {
        background: `rgba(0,0,0,0.1) !important`,
    },

    ':checked + label:after': {
        left: '30px',
        transform: 'translateX(-100%)',
    },
});

const Label = styled('label', {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '::before': {
        content: '""',
        display: 'inline-block',
        width: '14px',
        height: '14px',
        backgroundClip: 'content-box',
        borderRadius: '50%',
        marginRight: '8px',
    },
});

const themes = {
    default: {
        styles: {
            border: '1px solid var(--color-border-radio-button)',
        },
        checkedStyles: {
            backgroundColor: 'var(--color-bg-radio-button)',
        },
    },
    inverted: {
        styles: {
            border: '1px solid var(--color-border-radio-button-inverted)',
        },
        checkedStyles: {
            backgroundColor: 'var(--color-bg-radio-button-inverted)',
        },
    },
};
const RadioInput = ({
    $style,
    checked,
    defaultChecked,
    id,
    label,
    name,
    onChange,
    readOnly = false,
    theme = 'default',
}) => {
    const [css] = useStyletron();
    const internalId = useMemo(() => id || generateRandomString(7), [id]);
    const { styles, checkedStyles } = themes[theme];

    return (
        <div className={css({ position: 'relative', ...$style })}>
            <Input
                $style={{ ':checked + label::before': checkedStyles }}
                checked={checked}
                defaultChecked={defaultChecked}
                id={internalId}
                name={name}
                readOnly={readOnly}
                type="radio"
                onChange={onChange}
                onClick={e => e.currentTarget.blur()}
            />
            <Label $style={{ '::before': styles }} htmlFor={internalId}>
                {label}
            </Label>
        </div>
    );
};

RadioInput.propTypes = {
    $style: PropTypes.object,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    theme: PropTypes.oneOf(['default', 'inverted']),
};

export default RadioInput;
