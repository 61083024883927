import { useSelector } from 'react-redux';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import BasketIcon from 'components/icons/BasketIcon';
import BasketIconMobile from 'components/icons/BasketIconMobile';

const NumberBadge = styled('div', {
    position: 'absolute',
    top: ['calc(50% + 1px)', null, null, null, null, 'calc(50% - 1px)'],
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
});

const BasketCounterIcon = () => {
    const [css] = useStyletron();

    const basketItems = useSelector(s => s.basket.items);
    const numberOfItems = basketItems.reduce((sum, item) => (sum += item.quantity), 0);

    // @todo: We should add number badge logic to the SVG in BasketIcon.
    return (
        <div className={css({ position: 'relative', display: 'flex' })}>
            {numberOfItems > 0 && (
                <NumberBadge>
                    <span
                        className={css(
                            responsiveTextStyles([
                                'Misc/10_100_-00625',
                                null,
                                null,
                                null,
                                null,
                                numberOfItems > 9 ? 'Misc/8_100_-00625' : 'Misc/10_100_-00625',
                            ])
                        )}
                    >
                        {numberOfItems > 99 ? 99 : numberOfItems}
                    </span>
                </NumberBadge>
            )}
            <BasketIcon
                $style={{
                    display: ['none', null, null, null, null, 'block'],
                    height: '16px',
                }}
            />
            <BasketIconMobile
                $style={{
                    display: ['block', null, null, null, null, 'none'],
                    height: '24px',
                }}
            />
        </div>
    );
};

export default BasketCounterIcon;
