import PropTypes from 'prop-types';
import { bannerThemes } from 'config/branding/component-themes/banner-themes';
import textStyles from 'config/branding/textStyles';
import FlipClock from '@activebrands/core-web/components/FlipClock';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    ...textStyles['Misc/14_110_-0175'],
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    zIndex: 'var(--zindex-banner)',

    [media.min['desktop.sm']]: {
        position: 'absolute',
        top: 'var(--height-header)',
    },
});

const Banner = ({
    $flipClockItemStyle = {},
    $flipClockWrapperStyle = {},
    spin = true,
    texts = [],
    theme = 'black',
    to = '',
}) => {
    const [css] = useStyletron();
    const isLink = !!to;

    return (
        <Wrapper $as={isLink && Link} $isLink={isLink} id="banner" to={to}>
            <FlipClock
                spin={spin}
                $style={{
                    width: '100vw',
                    ...textStyles['Misc/14_110_-0175'],
                    ...bannerThemes[theme],
                    ...$flipClockWrapperStyle,
                }}
            >
                {texts.map((text, index) => (
                    <li
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                            height: '100%',
                            ...$flipClockItemStyle,
                        })}
                        key={index}
                    >
                        <RichText data={text} />
                    </li>
                ))}
            </FlipClock>
        </Wrapper>
    );
};

Banner.propTypes = {
    $flipClockItemStyle: PropTypes.object,
    $flipClockWrapperStyle: PropTypes.object,
    spin: PropTypes.bool,
    texts: PropTypes.array.isRequired,
    theme: PropTypes.oneOf(['black']),
    to: PropTypes.string,
};

export default Banner;
