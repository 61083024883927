import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const columnsWrappers = {
    default: loadable(() => import('./DefaultWrapper')),
};

const ColumnsWrapper = ({ children, numberOfItems, position, switchDesktopOrder, ...rest }) => {
    const layout = 'default';

    const Wrapper = columnsWrappers[layout];

    let contentPosition;

    // Three column
    if (numberOfItems === 3) {
        contentPosition = position === 0 ? 'flex-start' : position === 1 ? 'center' : 'flex-end';
    }

    // Two column
    if (numberOfItems === 2) {
        // Checks if the column is on the left side in desktop
        if ((position === 0 && !switchDesktopOrder) || (position === 1 && switchDesktopOrder)) {
            contentPosition = [null, null, 'flex-start'];
        } else {
            contentPosition = [null, null, 'flex-end'];
        }
    }

    return (
        <Wrapper
            contentPosition={contentPosition}
            marginTop={position === 1 ? ['16px', null, 0] : 0}
            numberOfItems={numberOfItems}
            {...rest}
        >
            {children}
        </Wrapper>
    );
};

ColumnsWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    numberOfItems: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    switchDesktopOrder: PropTypes.bool,
};

export default ColumnsWrapper;
