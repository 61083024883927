import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const MenuIcon = ({ color, width = '16px', height = '100%', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M0 12h16M0 8h10.667M0 4h16" stroke={color} />
    </SVG>
);

MenuIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

MenuIcon.defaultProps = {
    $style: {},
    color: 'currentColor',
    height: '16px',
    width: '100%',
};

export default MenuIcon;
