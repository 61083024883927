import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AddToBasketIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path stroke={color} d="M1.833 15.501V3.168h12.334v12.333H1.834Z" />
            <path stroke={color} d="M6 4.001V.668h4v3.333M8 5.332v8M12 9.332H4" />
        </SVG>
    );
};

AddToBasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AddToBasketIcon;
