import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';

const NotificationWrapper = styled('div', {
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'auto',
    backgroundColor: 'var(--color-bg-notification-wrapper)',

    [media.min['desktop.sm']]: {
        margin: '4px',
        width: '360px',
        border: '1px solid var(--color-border-notification-wrapper)',
    },
});

export default NotificationWrapper;
