import PropTypes from 'prop-types';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import ArrowIcon from 'components/icons/ArrowIcon';

const ArrowPagination = ({ slider, $style = {} }) => {
    if (!slider) {
        return null;
    }
    const [css] = useStyletron();

    const { index, isInfinite, numberOfSlides, slidesPerView } = useSlider(slider, [
        'index',
        'isInfinite',
        'numberOfSlides',
        'slidesPerView',
    ]);

    const hide = !isInfinite && numberOfSlides === slidesPerView;
    const isBeginning = isInfinite ? false : index === 0;
    const isEnd = isInfinite
        ? false
        : slidesPerView === numberOfSlides || index + Math.floor(slidesPerView) >= numberOfSlides;

    const style = {
        ...{ display: hide ? 'none' : 'block' },
        ...$style,
    };

    return (
        <div className={css({ display: 'flex', flexDirection: 'row' })}>
            <ThemeButton $style={style} disabled={isBeginning} onClick={() => slider.slideToPrev()}>
                <ArrowIcon $style={{ transform: 'rotate(-180deg)' }} />
            </ThemeButton>
            <ThemeButton $style={{ ...style, borderLeft: 0 }} disabled={isEnd} onClick={() => slider.slideToNext()}>
                <ArrowIcon />
            </ThemeButton>
        </div>
    );
};

ArrowPagination.propTypes = {
    $style: PropTypes.object,
    slider: PropTypes.object.isRequired,
};

export default ArrowPagination;
