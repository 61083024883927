import SVG from '@activebrands/core-web/components/SVG';

interface SearchIconMobileProps {
    $style?: any;
    color?: string;
    fill?: string;
    height?: string | Array<string | null>;
    width?: string | Array<string | null>;
}

const SearchIconMobile = ({
    $style = {},
    color = 'currentColor',
    height = 'auto',
    width = '16px',
    ...rest
}: SearchIconMobileProps) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} viewBox="0 0 24 24" fill="none" {...rest}>
            <path
                d="M24 24L16.7175 16.7175M16.7175 16.7175C18.4367 14.9984 19.5 12.6234 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C12.6234 19.5 14.9984 18.4367 16.7175 16.7175Z"
                stroke={color}
            />
        </SVG>
    );
};

export default SearchIconMobile;
