import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import Badges from 'components/Badges';
import ThemeButton from 'components/buttons/ThemeButton';

const FeaturedItems = ({ $style = {}, items = [] }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '8px 0',
                ...$style,
            })}
        >
            {items.map(({ badgeText = '', badgeTheme = 'primary', label = '', link = {} }) => {
                return (
                    <div className={css({ alignItems: 'flex-start', display: 'flex' })} key={label}>
                        <ThemeButton
                            isVolatile
                            theme="link"
                            $style={{
                                padding: '6px 12px',
                                margin: 'unset',
                            }}
                            fontKeys="Secondary/18_120_-0225"
                            as={Link}
                            to={link.url}
                        >
                            {label}
                        </ThemeButton>
                        {badgeText && badgeTheme && (
                            <Badges
                                $badgesStyles={{
                                    padding: '4px',
                                    borderRadius: '4px',
                                    ...textStyles['Secondary/18_120_-0225'],
                                }}
                                $style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    bottom: 'unset',
                                    width: 'auto',
                                }}
                                items={[{ colorTheme: badgeTheme, displayText: badgeText, internalName: badgeText }]}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

FeaturedItems.propTypes = {
    $style: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FeaturedItems;
