import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import StateButton from 'components/buttons/StateButton';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const ShopTheLook = ({
    background,
    backgroundOverlay,
    heading = '',
    headingFontKeys = 'Misc/20_120_-025',
    headingType = 'h2',
    id,
    buttonLabel = '',
    buttonTheme = '',
    products = [],
    tag = '',
    text = '',
    textColor = 'black',
}) => {
    const [css] = useStyletron();
    const textColorStyle = getTextColorStyles(textColor);

    return (
        <div className={css({ position: 'relative', width: '100%', height: '100%', ...textColorStyle })} id={id}>
            <BackgroundDynamic background={background} backgroundOverlay={backgroundOverlay} loading="lazy" />
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    flexDirection: ['column', null, null, null, 'row'],
                    padding: 'var(--margin-content)',
                })}
            >
                <div
                    className={css({
                        marginTop: 'auto',
                        maxWidth: [null, null, null, null, '360px'],
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        gap: '16px',
                        marginBottom: ['16px', null, null, null, '0'],
                    })}
                >
                    {tag && <Paragraph fontKeys="Secondary/14_130_-0175">{tag}</Paragraph>}
                    <div
                        className={css({
                            maxWidth: [null, null, null, null, '360px'],
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                            gap: '16px',
                            marginBottom: ['16px', null, null, null, '0'],
                        })}
                    >
                        {heading && (
                            <Heading as={headingType} fontKeys={headingFontKeys}>
                                {heading}
                            </Heading>
                        )}
                        {text && (
                            <div
                                className={css({
                                    ...textStyles['Secondary/14_130_-0175'],
                                })}
                            >
                                <RichText data={text} />
                            </div>
                        )}
                    </div>
                </div>
                {buttonLabel && products.length > 0 && (
                    <StateButton
                        $style={{
                            marginLeft: 'auto',
                            width: ['100%', null, null, null, 'auto'],
                            marginTop: [null, null, null, null, 'auto'],
                        }}
                        theme={buttonTheme}
                        onClick={() => overlay.open('shop-the-look', { products })}
                    >
                        {buttonLabel}
                    </StateButton>
                )}
            </div>
        </div>
    );
};

ShopTheLook.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttonLabel: PropTypes.string,
    buttonTheme: PropTypes.string,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string.isRequired,
    products: PropTypes.array,
    tag: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
};

export default ShopTheLook;
