import Image from '@activebrands/core-web/components/Image';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { Technology } from '@activebrands/core-web/types/product';

interface TechnologiesProps {
    $style?: any;
    $imageStyle?: any;
    technologies: Array<Technology>;
    maxTechnologies?: number;
}

const Technologies = ({ $style = {}, $imageStyle = {}, technologies = [], maxTechnologies = 1 }: TechnologiesProps) => {
    const [css] = useStyletron();

    return (
        <div className={css({ display: 'flex', gap: '8px', ...$style })}>
            {technologies.slice(0, maxTechnologies).map(({ name, imageUrl }) => (
                <Image
                    $style={{ width: '48px', height: '48px', ...$imageStyle, objectFit: 'contain' }}
                    alt={name}
                    key={name}
                    loading="eager"
                    src={imageUrl}
                    title={name}
                />
            ))}
        </div>
    );
};

export default Technologies;
