import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

interface CloseIconMobileProps {
    $style?: any;
    color?: string;
    fill?: string;
    height?: string | Array<string | null>;
    width?: string | Array<string | null>;
}

const CloseIconMobile = ({
    $style = {},
    color = 'currentColor',
    fill = 'none',
    height = '24px',
    width = 'auto',
    ...rest
}: CloseIconMobileProps) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} viewBox="0 0 25 24" fill="none" {...rest}>
            <path d="M22.0664 2L2.06641 22" stroke={color} />
            <path d="M22.0664 22L2.06641 2" stroke={color} />
        </SVG>
    );
};

export default CloseIconMobile;
