import { useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import withHeightAuto from '@activebrands/core-web/components/withHeightAuto';
import media from '@activebrands/core-web/config/media';
import LipScoreServiceSmallReviewsBadge from '@activebrands/core-web/libs/lipscore/serviceReviewWidgets/LipScoreServiceSmallReviewsBadge';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import SweetProtectionIcon from 'components/icons/SweetProtectionIcon';
import SweetProtectionVerticalIcon from 'components/icons/SweetProtectionVerticalIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100%',
    color: 'var(--color-text-footer-primary)',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 12px 40px 12px',

    [media.min['tablet.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '8px',
        padding: '0',
    },

    [media.min['desktop.sm']]: {
        gap: '8px',
    },
});

const InformationWrapper = styled('div', {
    width: '100%',
    gap: '8px',
    display: 'flex',

    ':first-child': {
        order: '1',
    },

    [media.min['tablet.sm']]: {
        gridColumn: '1 / span 5',
        gridRow: '1/ span 2',
        display: 'grid',
        padding: '48px 24px',
    },

    [media.min['desktop.sm']]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
        borderRight: '1px solid var(--color-border-footer-primary)',
    },
});

const LogoWrapper = styled('div', {
    position: 'relative',
    gridColumn: '1 / span 2',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',

    [media.min['mobile.lg']]: {
        width: 'auto',
        gridColumn: '1 / span 1',
    },

    [media.min['tablet.sm']]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
});

const AccordionWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',

    [media.min['tablet.sm']]: {
        gridColumn: '7 / span 6',
        flexDirection: 'row',
        marginBottom: '0',
    },
});

const FeaturedWrapper = styled('div', {
    padding: '24px 0 48px',

    [media.min['mobile.lg']]: {
        padding: '48px 0',
    },

    [media.min['tablet.sm']]: {
        gridColumn: '7 / span 5',
        gridRow: '1 / span 1',
        padding: '48px 0 64px',
    },
});

const FeaturedLinksWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    [media.min['tablet.sm']]: {},
});

const MenuItem = styled('div', {
    borderTop: ['1px solid var(--color-border-footer-primary)', null, null, '0'],

    ':last-child': {
        borderBottom: ['1px solid var(--color-border-footer-primary)', null, null, '0'],
    },

    [media.min['tablet.sm']]: {
        width: '33.33%',
        borderTop: 0,
        wordWrap: 'break-word',
    },
});

const MenuHeaderMobile = styled('button', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '12px 0',
    lineHeight: '100%',

    [media.min['tablet.sm']]: {
        display: 'none',
    },
});

const MenuHeader = styled('div', {
    display: 'none',

    [media.min['tablet.sm']]: {
        visibility: 'visible',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'unset',
        height: 'unset',
        marginBottom: '16px',
        lineHeight: '100%',
    },
});

const Content = withHeightAuto(
    styled('div', {
        borderRadius: '0 0 15px 15px',
        overflow: 'hidden',
        transitionDuration: '500ms',
        transitionProperty: 'height',
        transitionTimingFunction: 'var(--ease)',

        [media.min['tablet.sm']]: {
            height: 'auto !important',
            overflow: 'visible',
            transition: 'none',
        },
    })
);

const InnerContent = styled('nav', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px 0',

    [media.min['tablet.sm']]: {
        gap: '16px',
        padding: '0',
    },
});

const StyledFeaturedLink = styled(Link, {
    display: 'inline-block',
    padding: '13px 12px',
    margin: '-0.5px',
    border: '1px solid var(--color-button-border-footer)',
    textDecoration: 'none',
    ...textStyles['Misc/16_100_-02'],

    [media.min['tablet.sm']]: {
        padding: '12px',
    },
});

const StyledLink = styled(Link, {
    display: 'block',
    ...textStyles['Misc/16_100_-02'],
});

const InformationTextWrapper = styled('div', {
    gridColumn: '3 / span 10',

    [media.min['tablet.sm']]: {
        gridColumn: '2 / span 3',
    },

    [media.min['desktop.sm']]: {
        gridColumn: '2 / span 3',
    },
});

const FooterMenuInfo = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    ...textStyles['Secondary/14_130_-0175'],
});

const FooterMenu = ({ items, informationHeading, featuredLinks, featuredLinksLabel, informationParagraphs }) => {
    const [css] = useStyletron();
    const headingSettings = transformHeading({ type: informationHeading?.[0]?.type, defaultType: 'h3' });

    return (
        <Wrapper>
            <InformationWrapper>
                <LogoWrapper>
                    <SweetProtectionVerticalIcon color="var(--fill-logo-footer)" width="50%" />
                </LogoWrapper>
                <InformationTextWrapper>
                    {informationHeading?.length > 0 && (
                        <Heading $style={{ marginBottom: '24px' }} as={headingSettings.type} fontKeys="Primary/24_-015">
                            {informationHeading[0].text}
                        </Heading>
                    )}
                    {informationParagraphs?.length > 0 && (
                        <FooterMenuInfo>
                            {informationParagraphs.map((paragraph, paragraphIndex) => (
                                <RichText key={`${paragraph._uid}-${paragraphIndex}`} data={paragraph.richText} />
                            ))}
                        </FooterMenuInfo>
                    )}
                    <div className={css({ marginTop: ['24px', null, null, '40px'] })}>
                        <LipScoreServiceSmallReviewsBadge type="long" />
                    </div>
                </InformationTextWrapper>
            </InformationWrapper>
            <SweetProtectionIcon
                color="var(--fill-logo-footer)"
                width="15%"
                $style={{ display: ['none', null, 'block', 'none'] }}
            />
            <FeaturedWrapper>
                <Heading
                    $style={{ marginBottom: ['16px', null, null, '24px'] }}
                    as="h3"
                    fontKeys={['Secondary/20_100_-0375', null, null, 'Secondary/24_100_-03']}
                >
                    {featuredLinksLabel || 'Inspiration for you'}
                </Heading>
                <FeaturedLinksWrapper>
                    {featuredLinks?.map((link, index) => (
                        <StyledFeaturedLink isVolatile key={`${link?.label}-${index}`} to={link.link.url}>
                            {link.label}
                        </StyledFeaturedLink>
                    ))}
                </FeaturedLinksWrapper>
            </FeaturedWrapper>
            <AccordionWrapper
                $style={{
                    gap: [0, null, '8px', null, 0],
                    padding: ['0 0 56px 0'],
                }}
            >
                {items.map((footerItem, footerItemIndex) => {
                    const [open, setOpen] = useState(false);
                    const [iconState, setIconState] = useState('plus');
                    return (
                        <MenuItem key={`${footerItem.label}-${footerItemIndex}`}>
                            <MenuHeaderMobile
                                type="button"
                                onClick={() => {
                                    setOpen(!open);
                                    setIconState(iconState === 'minus' ? 'plus' : 'minus');
                                }}
                            >
                                <Paragraph fontKeys="Primary/32_-04">{footerItem.label}</Paragraph>
                                <StateIcon
                                    $style={{ display: [null, null, null, 'none'] }}
                                    size="16px"
                                    state={iconState}
                                />
                            </MenuHeaderMobile>
                            <MenuHeader>
                                <Paragraph fontKeys="Misc/12_100_-015">{footerItem.label}</Paragraph>
                            </MenuHeader>
                            <Content height={open ? 'auto' : 0}>
                                <InnerContent>
                                    {footerItem?.menuItems?.map((footerMenuItem, footerMenuItemIndex) => (
                                        <StyledLink
                                            isVolatile
                                            key={`${footerMenuItem.label}-${footerItemIndex}-${footerMenuItemIndex}`}
                                            rel={footerMenuItem.follow === 'no_follow' ? 'nofollow' : undefined}
                                            to={footerMenuItem.link?.url}
                                        >
                                            {footerMenuItem.label}
                                        </StyledLink>
                                    ))}
                                </InnerContent>
                            </Content>
                        </MenuItem>
                    );
                })}
            </AccordionWrapper>
        </Wrapper>
    );
};

FooterMenu.defaultProps = {
    informationHeading: PropTypes.shape({
        type: [],
    }),
};

FooterMenu.propTypes = {
    featuredLinks: PropTypes.array,
    featuredLinksLabel: PropTypes.string,
    informationHeading: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    informationParagraphs: PropTypes.array,
    items: PropTypes.array,
};

export default FooterMenu;
