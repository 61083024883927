import React from 'react';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import { styled } from 'styletron-react';

const Wrapper = styled('div', {});

const OverlayContent = ({
    content,
    headingStyles,
    paragraphStyles,
    buttonStyles,
    wrapperStyles,
    fontKeys = {
        heading: 'Primary/40_-05',
        button: 'Misc/20_120_-025',
        paragraph: 'Secondary/14_130_-0175',
    },
}) => {
    const OverlayHeading = styled(Heading, {
        marginBottom: '16px',
        ...headingStyles,
    });

    const OverlayParagraph = styled(Paragraph, {
        marginBottom: '32px',
        ...paragraphStyles,
    });

    const OverlayButton = styled(ThemeButton, {
        ...buttonStyles,
    });

    const availableComponents = {
        paragraph: OverlayParagraph,
        heading: OverlayHeading,
        button: OverlayButton,
    };

    return (
        <Wrapper $style={{ paddingTop: '80px', ...wrapperStyles }}>
            {content.map((item, index) => {
                const componentName = item.component || null;
                const ContentComponent = availableComponents[componentName] || null;

                return (
                    <ContentComponent
                        as={item.component === 'button' && Link}
                        fontKeys={fontKeys[componentName]}
                        key={index}
                        label={item.label}
                        to={item.url}
                        type={item.type}
                        theme={item.theme}
                    >
                        <RichText data={item.label || item.text} />
                    </ContentComponent>
                );
            })}
        </Wrapper>
    );
};

OverlayContent.propTypes = {
    buttonStyles: PropTypes.object,
    content: PropTypes.object,
    fontKeys: PropTypes.object,
    headingStyles: PropTypes.object,
    paragraphStyles: PropTypes.object,
    wrapperStyles: PropTypes.object,
};

export default OverlayContent;
