import fm from 'format-message';
import PropTypes from 'prop-types';
import CheckIcon from 'components/icons/CheckIcon';
import Paragraph from 'components/text/Paragraph';

const SuccessMessage = ({ $style = {} }) => (
    <Paragraph $style={{ display: 'flex', alignItems: 'center', gap: '8px', ...$style }} fontKeys="Misc/12_100_-015">
        {fm('Added')} <CheckIcon />
    </Paragraph>
);

SuccessMessage.propTypes = {
    $style: PropTypes.object,
};

export default SuccessMessage;
