import SVG from '@activebrands/core-web/components/SVG';

interface AccountIconMobileProps {
    $style?: any;
    color?: string;
    fill?: string;
    height?: string | Array<string | null>;
    width?: string | Array<string | null>;
}

const AccountIconMobile = ({
    $style = {},
    color = 'currentColor',
    fill = 'none',
    height = '100%',
    width = '16px',
    ...rest
}: AccountIconMobileProps) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} viewBox="0 0 25 24" fill={fill} {...rest}>
            <rect width="10" height="10" x="17.668" y=".5" stroke={color} rx="5" transform="rotate(90 17.668 .5)" />
            <path stroke={color} d="m21.4609 13.5 2.7071 2.7071V23.5H1.16797v-7.2929L3.87508 13.5H21.4609Z" />
        </SVG>
    );
};

export default AccountIconMobile;
