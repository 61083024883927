import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import Button from 'components/buttons/Button';
import ArrowIcon from 'components/icons/ArrowIcon';

const Wrapper = styled('div', {
    position: 'relative',
    padding: '24px 16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    transitionDuration: 'unset',
    transitionProperty: 'unset',
    transitionTimingFunction: 'unset',
    marginTop: 'calc(var(--height-header))',

    [media.min['desktop.sm']]: {
        marginTop: 'unset',
        marginBottom: '16px',
        padding: 'unset',
    },
});

const OverlayHeading = ({ firstLevel, menuValue, setMenuValue, parentLabel = '' }) => {
    return (
        <Wrapper>
            <Button
                $style={{
                    display: ['flex', null, null, null, null, 'none'],
                    marginRight: '8px',
                    alignItems: 'center',
                    transitionDuration: 'unset',
                    transitionProperty: 'unset',
                    transitionTimingFunction: 'unset',
                    ...textStyles['Misc/14_110_-0175'],
                }}
                onClick={() => setMenuValue({ current: firstLevel ? null : menuValue.prev, prev: menuValue.current })}
            >
                <ArrowIcon $style={{ marginRight: '8px', transform: 'rotate(180deg)' }} size="12px" />
                {parentLabel}
            </Button>
        </Wrapper>
    );
};

OverlayHeading.propTypes = {
    firstLevel: PropTypes.bool,
    menuValue: PropTypes.object,
    parentLabel: PropTypes.string,
    setMenuValue: PropTypes.func,
};

export default OverlayHeading;
