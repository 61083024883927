import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    width: '100%',
    height: '100%',
    display: ['flex', null, null, null, 'grid'],
    flexDirection: 'column',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: ['32px 8px', null, '32px 12px', null, '0 16px'],
});

const ImageWrapper = styled('div', {
    position: 'relative',
    aspectRatio: [
        'var(--ratio-vertical-secondary)',
        null,
        'var(--ratio-horizontal-primary)',
        null,
        'var(--ratio-vertical-secondary)',
    ],
});

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: ['32px', null, null, null, '48px'],
    padding: ['0 var(--margin-content)', null, null, null, '0'],
});

const Information = ({
    background,
    backgroundOverlay,
    buttons = [],
    contentOrder = [{ image: 1 }, { text: 2 }],
    switchDesktopOrder = false,
    heading,
    headingType = 'h2',
    headingStyle = 'md',
    id = '',
    tag,
    text,
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const tagFontKeys = {
        sm: ['Secondary/16_100_-02', null, null, null, 'Secondary/24_100_-03'],
        md: ['Secondary/24_100_-03', null, 'Secondary/32_100_-06'],
    };

    const headingFontKeys = {
        sm: ['Secondary/32_100_-06', null, 'Secondary/32_100_-06', null, 'Secondary/48_100_-09'],
        md: ['Primary/48_-09', null, 'Primary/64_-12', null, 'Primary/72_-135'],
    };

    return (
        <Wrapper id={id}>
            <ImageWrapper
                $style={{
                    gridColumn: switchDesktopOrder ? '7 / span 5' : '2 /span 5',
                    order: contentOrder.image,
                }}
            >
                <BackgroundDynamic
                    $style={{ position: 'unset' }}
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading="lazy"
                />
            </ImageWrapper>
            <ContentWrapper
                $style={{
                    gridColumn: switchDesktopOrder ? '2 / span 4' : '8 / span 4',
                    order: contentOrder.text,
                }}
            >
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    })}
                >
                    {tag && <Tag fontKeys={tagFontKeys[headingStyle]}>{tag}</Tag>}
                    {heading && (
                        <Heading as={headingType} fontKeys={headingFontKeys[headingStyle]}>
                            {heading}
                        </Heading>
                    )}
                </div>
                {text && (
                    <Paragraph
                        $style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                        fontKeys={['Secondary/14_130_-0175', null, null, null, 'Secondary/16_130_-02']}
                    >
                        <RichText data={text} />
                    </Paragraph>
                )}
                {hasButtons && <ButtonsList buttons={buttons} />}
            </ContentWrapper>
        </Wrapper>
    );
};

Information.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentOrder: PropTypes.array,
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    switchDesktopOrder: PropTypes.bool,
    tag: PropTypes.string,
    text: PropTypes.object,
};

export default Information;
