import Image from '@activebrands/core-web/components/Image';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import type { ImageLoading, ImageSrc } from '@activebrands/core-web/types/components/image';

interface SquareImageProps {
    $selectedStyleAfter?: any;
    $selectedStyleBefore?: any;
    $selectedStyle?: any;
    $style?: any;
    borderColor?: string;
    isSelected?: boolean;
    loading?: ImageLoading;
    name: string;
    selectedBorderColor?: string;
    src?: ImageSrc;
}

const SquareImage = ({
    $selectedStyleAfter = {},
    $selectedStyleBefore = {},
    $selectedStyle = {},
    $style = {},
    borderColor = 'var(--color-swatches-border-color)',
    isSelected = false,
    loading = 'eager',
    name = '',
    selectedBorderColor = 'var(--color-swatches-border-color-highlight)',
    src,
    ...rest
}: SquareImageProps) => {
    const [css] = useStyletron();
    const hasImage = !!src;

    const selectedStyle = isSelected
        ? {
              border: `1px solid ${selectedBorderColor}`,
              ...$selectedStyle,

              ':before': {
                  content: '""',
                  position: 'absolute',
                  zIndex: 3,
                  border: '1px solid var(--color-swatches-border-color)',
                  top: '1px',
                  left: '1px',
                  width: '8px',
                  height: '8px',
                  ...$selectedStyleBefore,
              },

              ':after': {
                  content: '""',
                  position: 'absolute',
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  boxShadow: `inset 0px 0px 0px 1px var(--color-swatches-border-color-highlight-inner)`,
                  ...$selectedStyleAfter,
              },
          }
        : {};

    const style = {
        height: '12px',
        width: '12px',
        transform: 'rotate(45deg)',
        overflow: 'hidden',
        border: `1px solid ${borderColor}`,
        ...selectedStyle,
        ...$style,
    };

    return hasImage ? (
        <Image $style={style} alt={name} loading={loading} src={src} title={name} {...rest} />
    ) : (
        <div className={css(style)} title={name} />
    );
};

export default SquareImage;
