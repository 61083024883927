import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SubMenuLevel from 'components/Header/Navigation/PrimaryMenu/SubMenuLevel';
import ThemeButton from 'components/buttons/ThemeButton';

const MenuDot = styled('div', ({ $isActive }) => ({
    position: 'absolute',
    display: 'flex',
    justifyCcontent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--color-icon-navigation-cube)',
    width: '8px',
    height: '8px',
    right: '12px',
    zIndex: 'calc(var(--zindex-header) + 0)',
    transform: 'rotate(45deg) ',

    [media.min['desktop.sm']]: {
        zIndex: 'calc(var(--zindex-header) + 8)',
        right: $isActive ? '15px' : '40px',
        transition: 'var(--duration-fast) var(--ease)',
    },
}));

const PrimaryMenu = ({ items = [], menuValue = {}, resetNavigation = () => null, setMenuValue }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    return (
        <ul
            className={css({
                display: 'flex',
                flexDirection: 'column',
                margin: '0 0 24px 0',
            })}
        >
            {items?.map(levelOneItem => {
                const hasSubmenu = levelOneItem.submenus?.length > 0;
                const isActive = menuValue.current === levelOneItem._uid;
                const subMenuIsActive = levelOneItem.submenus.some(subMenu => subMenu._uid === menuValue.current);

                return (
                    <li key={levelOneItem.label}>
                        <div
                            className={css({
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                position: 'relative',
                            })}
                        >
                            <ThemeButton
                                theme="link"
                                $style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                    whiteSpace: 'normal',
                                    textAlign: 'left',

                                    [media.min['desktop.sm']]: {
                                        ':hover > div': {
                                            transform: 'rotate(45deg)',
                                            right: '15px',
                                        },
                                    },
                                }}
                                fontKeys="Primary/32_-04"
                                active={isActive}
                                as={Link}
                                rel={levelOneItem.follow === 'no_follow' ? 'nofollow' : undefined}
                                to={levelOneItem?.link?.url}
                                onClick={e => {
                                    if (hasSubmenu) {
                                        overlay.open('navigation');
                                        e.preventDefault();
                                        setMenuValue({
                                            current: hasSubmenu ? levelOneItem._uid : null,
                                            prev: menuValue.current,
                                        });
                                    } else {
                                        resetNavigation();
                                    }
                                }}
                            >
                                <span className={css({ width: '80%' })}>{levelOneItem.label}</span>
                                {hasSubmenu && <MenuDot $isActive={isActive} />}
                            </ThemeButton>
                        </div>

                        <SubMenuLevel
                            featuredItems={levelOneItem.featuredItems}
                            featuredLabel={levelOneItem.featuredLabel}
                            isActive={isActive || subMenuIsActive}
                            level={1}
                            menuValue={menuValue}
                            parentLabel={levelOneItem.label}
                            parentLink={levelOneItem.link}
                            resetNavigation={resetNavigation}
                            setMenuValue={setMenuValue}
                            subMenuIsActive={subMenuIsActive}
                            submenus={levelOneItem.submenus}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

PrimaryMenu.propTypes = {
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    resetNavigation: PropTypes.func.isRequired,
    setMenuValue: PropTypes.func.isRequired,
};

export default PrimaryMenu;
