import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { isStoryblokPreview } from '@activebrands/core-web/utils/constants';
import PromotionGrid from 'components/content-components/Promotional/PromotionGrid';
import PromotionSlider from 'components/content-components/Promotional/PromotionSlider';

const layouts = {
    slider: PromotionSlider,
    grid: PromotionGrid,
};

const ArticlePromotion = ({
    articles = [],
    button = {},
    columnSizes = [],
    heading,
    tagFontKeys = ['Secondary/24_100_-03', null, null, 'Secondary/32_100_-06'],
    headingFontKeys = ['Primary/48_-09', null, null, 'Primary/64_-12'],
    headingType,
    id,
    imageSettings = {},
    layout = 'slider',
    text,
    slidesPerView,
    tag,
}) => {
    const [css] = useStyletron();

    if (isStoryblokPreview) {
        return (
            <div className={css({ padding: '64px 0', textAlign: 'center' })}>
                <p>Articles are unavailable in preview</p>
            </div>
        );
    }

    if (articles.length < 1) {
        return null;
    }

    const PromotionLayout = layouts[layout];

    return (
        <PromotionLayout
            button={button}
            columnSizes={columnSizes}
            heading={heading}
            headingFontKeys={headingFontKeys}
            tagFontKeys={tagFontKeys}
            headingType={headingType}
            id={id}
            imageSettings={imageSettings}
            items={articles}
            slidesPerView={slidesPerView}
            tag={tag}
            text={text}
            type="article"
        />
    );
};

ArticlePromotion.propTypes = {
    articles: PropTypes.array,
    button: PropTypes.object,
    columnSizes: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    layout: PropTypes.string,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
};

export default ArticlePromotion;
