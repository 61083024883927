import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import Events from '@activebrands/core-web/libs/Events';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import AddToBasketIcon from 'components/icons/AddToBasketIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';

const StyledButton = styled('button', {
    [media.min['desktop.sm']]: {
        ':hover': {
            '::before': {
                content: fm('"Add to basket"'),
                position: 'absolute',
                right: '24px',
                top: '2px', // alignment
                display: 'inline-block',
                whiteSpace: 'nowrap',
                ...textStyles['Misc/12_100_-015'],
            },
        },
    },
});

const AddToBasketButton = ({
    $style = {},
    activePrice,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    instantBuy = false,
    name,
    originalPrice,
    setButtonState,
    setIsOpen,
    uri,
    variations,
}) => {
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();

    const handleClick = async (e, variation) => {
        setButtonState('loading');
        e.preventDefault();

        try {
            // TODO: Control tracking works when this works
            await dispatch(addBasketItem(variation.id, variation.trackingMeta));
            setButtonState('success');

            notifications.push(AddedToBasket, {
                ...addedToBasketData,
                size: variations.filter(item => item.id === variation.id)?.[0]?.name || '',
            });

            if (addedToBasketAlgoliaData) {
                Events.trigger(SearchEvents.PRODUCT_ADDED, addedToBasketAlgoliaData);
            }
        } catch (error) {
            logger.error(error);
            setButtonState('error');
        } finally {
            setTimeout(() => setButtonState('default'), 2800);
        }
    };

    return (
        <>
            <StyledButton
                $style={{
                    display: ['flex', null, null, 'none'],
                    ...$style,
                }}
                type="button"
                onClick={e => {
                    if (instantBuy) {
                        handleClick(e, variations[0]);
                        return;
                    }

                    e.preventDefault();
                    overlay.open('quickshop', {
                        activePrice,
                        addedToBasketAlgoliaData,
                        addedToBasketData,
                        color,
                        name,
                        originalPrice,
                        setButtonState,
                        uri,
                        variations,
                    });
                }}
            >
                <AddToBasketIcon />
            </StyledButton>
            <StyledButton
                $style={{ display: ['none', null, null, 'flex'], ...$style }}
                type="button"
                onClick={e => {
                    if (instantBuy) {
                        handleClick(e, variations[0]);
                        return;
                    }

                    e.preventDefault();
                    setIsOpen(true);
                }}
            >
                <AddToBasketIcon />
            </StyledButton>
        </>
    );
};

AddToBasketButton.propTypes = {
    $style: PropTypes.object,
    activePrice: PropTypes.string,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    instantBuy: PropTypes.bool,
    name: PropTypes.string,
    originalPrice: PropTypes.string,
    setButtonState: PropTypes.func,
    setIsOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default AddToBasketButton;
