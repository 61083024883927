import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';

const StyledParagraph = styled('p');

const Paragraph = ({ $style = {}, as = 'p', children, fontKeys = 'Misc/16_100_-02', ...rest }) => {
    const fontStyle = responsiveTextStyles(fontKeys);
    const style = { ...fontStyle, ...$style };

    return (
        <StyledParagraph $as={as} $style={style} {...rest}>
            {children}
        </StyledParagraph>
    );
};

Paragraph.propTypes = {
    $style: PropTypes.object,
    as: PropTypes.string,
    children: PropTypes.node,
    fontKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default Paragraph;
