// @TODO FontWeights should depend on the fonts
export const fontWeights = [200, 300, 400, 500, 600, 700, 800, 900];

// @TODO Style should depends on the font
// https://stackoverflow.com/questions/56949513/typescript-type-of-a-property-dependent-on-another-property-within-the-same-obj
export interface TextStyle {
    fontFamily: string;
    fontSize: number;
    fontStyle: string;
    fontWeight?: string | number;
    letterSpacing: string;
    lineHeight: string;
    textDecoration?: string;
    textTransform: string;
}

const textStyles: Record<string, TextStyle> = {
    // Primary font styles
    'Primary/96_-24': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 96,
        letterSpacing: '-0.24rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/80_-15': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 80,
        letterSpacing: '-0.15rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/72_-135': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 72,
        letterSpacing: '-0.135rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/64_-12': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 64,
        letterSpacing: '-0.12rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/56_-105': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 56,
        letterSpacing: '-0.105rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/48_-09': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 48,
        letterSpacing: '-0.09rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/40_-05': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 40,
        letterSpacing: '-0.05rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/32_-04': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-0.04rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/24_-015': {
        fontFamily: 'GZA',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 24,
        letterSpacing: '-0.015rem',
        lineHeight: '100%',
        textTransform: 'none',
    },

    // Secondary font styles
    'Secondary/64_100_-12': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 64,
        letterSpacing: '-0.12rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/56_100_-105': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 56,
        letterSpacing: '-0.105rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/48_100_-09': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 48,
        letterSpacing: '-0.09rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/40_100_-075': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 40,
        letterSpacing: '-0.075rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/32_100_-06': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-0.06rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/28_100_-0525': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 28,
        letterSpacing: '-0.0525rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/24_100_-03': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 24,
        letterSpacing: '-0.03rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/20_100_-0375': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 20,
        letterSpacing: '-0.0375rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/18_120_-0225': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-0.0225rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/16_100_-02': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.02rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/16_130_-02': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.02rem',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Secondary/14_130_-0175': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-0.0175rem',
        lineHeight: '130%',
        textTransform: 'none',
    },

    // Misc font styles
    'Misc/32_120_-06': {
        fontFamily: 'Aktiv Grotesk',
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-0.06rem',
        lineHeight: '120%',
        fontWeight: 400,
        textTransform: 'none',
    },
    'Misc/24_120_-03': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 24,
        letterSpacing: '-0.03rem',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Misc/20_120_-025': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 20,
        letterSpacing: '-0.025rem',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Misc/16_120_500_-02': {
        fontFamily: 'Aktiv Grotesk',
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.02rem',
        lineHeight: '120%',
        fontWeight: 500,
        textTransform: 'none',
    },
    'Misc/16_100_-02_LT': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.02rem',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'line-through',
    },
    'Misc/16_100_-02': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.02rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Misc/14_110_500_-0175': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-0.0175rem',
        lineHeight: '110%',
        textTransform: 'none',
    },
    'Misc/14_110_-0175': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-0.0175rem',
        lineHeight: '110%',
        textTransform: 'none',
    },
    'Misc/14_130_-0175': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-0.0175rem',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Misc/14_100_-0175_LT': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-0.0175rem',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'line-through',
    },

    'Misc/12_130_-01': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-0.01rem',
        lineHeight: '130%',
        textTransform: 'none',
    },

    'Misc/12_110_-015': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-0.015rem',
        lineHeight: '110%',
        textTransform: 'none',
    },
    'Misc/12_100_-015': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-0.015rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Misc/12_100_-015_LT': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-0.015rem',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'line-through',
    },
    'Misc/12_100_-015_UL': {
        fontFamily: 'Aktiv Grotesk',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-0.015rem',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'underline',
    },
    'Misc/10_100_-00625': {
        fontFamily: 'Aktiv Grotesk',
        fontStyle: 'normal',
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: '-0.00625rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Misc/8_100_-00625': {
        fontFamily: 'Aktiv Grotesk',
        fontStyle: 'normal',
        fontSize: 8,
        fontWeight: 500,
        letterSpacing: '-0.00625rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
};

export default textStyles;
